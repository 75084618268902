import React, { useEffect } from "react";
import Masonry from "masonry-layout";
import { Link } from "react-router-dom";
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import Loader from "../Components/Loader";
import Fancybox from "../Components/FancyBox";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getAllEventParticipantsnopagination } from "../Slice/events";
import pdfIcon from "../Assets/Images/pdf-icon.png"; // Path to your PDF icon

const CompetetionPictures = () => {
    const dispatch = useDispatch();
    const { isLoading, eventParticipantsWithoutPagination } = useSelector(state => state.event);

    useEffect(() => {
        dispatch(getAllEventParticipantsnopagination());
    }, [dispatch]);

    useEffect(() => {
        function masonryLayout() {
            const grid = document.querySelector(".grid");
            new Masonry(grid, {
                itemSelector: ".grid-item",
            });
        }
        const delay = 3000;
        const timeoutId = setTimeout(masonryLayout, delay);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <main id="content" className="site-main">
                    <Helmet>
                        <title> Nangal By Cycle Photo Gallery | Captured Moments on Two Wheels </title>
                        <meta
                            name="description"
                            content="Browse our gallery of breathtaking views, happy cyclists, and memorable moments from Nangal By Cycle tours and adventures."
                        />
                    </Helmet>
                    <section className="inner-banner-wrap">
                        <div
                            className="inner-baner-container"
                            style={{ backgroundImage: `url(${innerBannerImg1})` }}
                        >
                            <div className="container">
                                <div className="inner-banner-content">
                                    <h1 className="inner-title">Art Gallery</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="gallery-section">
                        <div className="container">
                            <div className="gallery-outer-wrap" style={{ marginTop: "30px" }}>
                                <div className="gallery-inner-wrap gallery-container grid">
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                        }}
                                    >
                                        {eventParticipantsWithoutPagination ? (
                                            eventParticipantsWithoutPagination?.map((item) => {
                                                const isPdf = typeof item.uploads === 'string' && item.uploads.endsWith(".pdf");
                                                return (
                                                    <div className="single-gallery grid-item" key={item?.id}>
                                                        <figure className="gallery-img">
                                                            <Link
                                                                target="_blank"
                                                                to={item.uploads}
                                                                data-fancybox="gallery"
                                                                data-caption={`Credit To: ${item.name}`}
                                                            >
                                                                <img
                                                                    src={item.uploads}
                                                                    alt={item?.name}
                                                                    style={{ position: "relative" }}
                                                                />
                                                                {isPdf && (
                                                                    <img
                                                                        src={pdfIcon}
                                                                        alt="PDF Icon"
                                                                    />
                                                                )}
                                                            </Link>
                                                        </figure>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div>Loading images...</div>
                                        )}
                                    </Fancybox>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
}

export default CompetetionPictures;               