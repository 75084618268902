import React, { useEffect } from 'react'
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { useParams } from 'react-router-dom';
import { stringToNumber } from '../../utils/encrypt';
import { useDispatch, useSelector } from 'react-redux';
import { getHeroDetail } from '../../Slice/heroSlice';
import Loader from '../../Components/Loader';
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton } from 'react-share';
import { Helmet } from 'react-helmet';

function HeroDetails() {
  const { id } = useParams();
  const lastIndex = id.lastIndexOf('-');
  const heroId = id.substring(lastIndex + 1);
  const intID = stringToNumber(heroId);
  const dispatch = useDispatch();
  const { heroDetails, isLoading } = useSelector((state) => state.hero);

  useEffect(() => {
    dispatch(getHeroDetail(intID));
  }, [dispatch, id])

  return (
    <main id="content" className="site-main">
      <Helmet>
        <title>{heroDetails?.name || 'Hero Details'} - Nangal By Cycle</title>
        <meta property="og:title" content={heroDetails?.name || 'Hero Details'} />
        <meta property="og:description" content={heroDetails?.recognition_description?.slice(0, 150)} />
        <meta property="og:image" content={heroDetails?.photo_url || 'https://nangalbycycle.com/default-image.jpg'} />
        <meta property="og:url" content={`https://nangalbycycle.com/hero/${id}`} />
      </Helmet>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: `url(${innerBannerImg1})` }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Hero Details</h1>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? <Loader /> : (
        <section className="">
          <div className="container mb-5 pb-5">
            <div className="row d-flex justify-content-center">
              <div className="col col-lg-9 col-xl-8">
                <div className="card">
                  <div className="rounded-top text-white d-flex flex-row" style={{ height: "200px", backgroundColor: "#F15B43" }}>
                    <div className="ms-4 mt-5 d-flex flex-column" style={{ width: 250, height: 250 }}>
                      <img src={heroDetails?.photo_url ? heroDetails?.photo_url : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"} alt="Hero-Img" className="img-fluid img-thumbnail mt-5" style={{ width: "100%", height: "100%", zIndex: "1", objectFit: "cover" }} />
                    </div>
                    <div className="ms-3" style={{ marginTop: "110px" }}>
                      <h4 className="text-white mb-2 fw-bold">{heroDetails?.recognition_title}</h4>
                      <p>{heroDetails?.name}</p>
                    </div>
                  </div>
                  <div className="card-body px-4 text-black card_body_divider" style={{ paddingBlock: "170px 20px", background: "#f3f3f3" }}>
                    <div className="text-body">
                      <div dangerouslySetInnerHTML={{ __html: heroDetails?.recognition_description }} />
                    </div>
                  </div>
                </div>
                <div className="post-socail-wrap mt-3">
                  <div className="social-icon-wrap d-flex justify-content-center">
                    {/* <div className="social-icon social-facebook">
                      <FacebookShareButton
                        className="facebook_btn"
                        quote={heroDetails?.name}
                        url={`https://nangalbycycle.com/hero/${id}`}
                      >  <i className="fab fa-facebook"></i>
                        <span>Facebook</span>
                      </FacebookShareButton>
                    </div> */}
                    <div className=" social-pinterest">
                      <PinterestShareButton
                        className="pinterest_btn px-5"
                        description={heroDetails?.name}
                        media={heroDetails?.photo_url}
                        url={`https://nangalbycycle.com/hero/${id}`}
                      >  <i className="ps-3 pe-2 fab fa-pinterest pe-2"></i>
                        <span className='pe-3 ps-2'>Pinterest</span>
                      </PinterestShareButton>
                    </div>

                    {/* <div className="social-icon social-linkedin">
                    <LinkedinShareButton
                      className="linkedin_btn"
                      description={heroDetails?.name}
                      summary={"test"}
                      source={"test2"}
                      url={`https://nangalbycycle.com/hero/${id}`}
                    >
                      <i className="fab fa-linkedin"></i>
                      <span>Linkedin</span>
                    </LinkedinShareButton>
                  </div> */}
                    <div className="social-twitter">
                      <TwitterShareButton className="twitter_btn" title={heroDetails?.name}
                        url={`https://nangalbycycle.com/hero/${id}`}>
                        <i className="ps-3 pe-2 fab fa-x-twitter"></i>
                        <span className='pe-3 ps-2'>Twitter</span>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      )}
    </main>
  )
}

export default HeroDetails

// import React, { useEffect } from 'react';
// import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
// import { useParams } from 'react-router-dom';
// import { stringToNumber } from '../../utils/encrypt';
// import { useDispatch, useSelector } from 'react-redux';
// import { getHeroDetail } from '../../Slice/heroSlice';
// import Loader from '../../Components/Loader';
// import { Helmet } from 'react-helmet';

// function HeroDetails() {
//   const { id } = useParams();
//   const dispatch = useDispatch();
//   const { heroDetails, heroLoading } = useSelector((state) => state.hero);

//   useEffect(() => {
//     const intID = stringToNumber(id);
//     dispatch(getHeroDetail(intID));
//   }, [dispatch, id]);

//   const shareOnFacebook = () => {
//     const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://nangalbycycle.com/hero/${id}`)}`;
//     window.open(url, '_blank', 'width=600,height=400');
//   };

//   const shareOnTwitter = () => {
//     const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(heroDetails?.name)}&url=${encodeURIComponent(`https://nangalbycycle.com/hero/${id}`)}`;
//     window.open(url, '_blank', 'width=600,height=400');
//   };

//   const shareOnLinkedIn = () => {
//     const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`https://nangalbycycle.com/hero/${id}`)}`;
//     window.open(url, '_blank', 'width=600,height=400');
//   };

//   const shareOnPinterest = () => {
//     const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(`https://nangalbycycle.com/hero/${id}`)}&media=${encodeURIComponent(heroDetails?.photo_url)}&description=${encodeURIComponent(heroDetails?.name)}`;
//     window.open(url, '_blank', 'width=600,height=400');
//   };

//   return (
//     <main id="content" className="site-main">
//       <Helmet>
//         <title>{heroDetails?.name || 'Hero Details'} - Nangal By Cycle</title>
//         <meta property="og:title" content={heroDetails?.name || 'Hero Details'} />
//         <meta property="og:description" content={heroDetails?.recognition_description?.slice(0, 150)} />
//         <meta property="og:image" content={heroDetails?.photo_url || 'https://nangalbycycle.com/default-image.jpg'} />
//         <meta property="og:url" content={`https://nangalbycycle.com/hero/${id}`} />
//       </Helmet>
//       <section className="inner-banner-wrap">
//         <div
//           className="inner-baner-container"
//           style={{ backgroundImage: `url(${innerBannerImg1})` }}
//         >
//           <div className="container">
//             <div className="inner-banner-content">
//               <h1 className="inner-title">Hero Details</h1>
//             </div>
//           </div>
//         </div>
//       </section>
//       {heroLoading ? <Loader /> : (
//         <section className="">
//           <div className="container mb-5 pb-5">
//             <div className="row d-flex justify-content-center">
//               <div className="col col-lg-9 col-xl-8">
//                 <div className="card">
//                   <div className="rounded-top text-white d-flex flex-row" style={{ height: "200px", backgroundColor: "#F15B43" }}>
//                     <div className="ms-4 mt-5 d-flex flex-column" style={{ width: "150px" }}>
//                       <img src={heroDetails?.photo_url ? heroDetails?.photo_url : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"} alt="Hero-Img" className="img-fluid img-thumbnail mt-5 mb-2" style={{ width: "150px", height: "150px", zIndex: "1" }} />
//                     </div>
//                     <div className="ms-3" style={{ marginTop: "130px" }}>
//                       <h4 className="text-white mb-2 fw-bold">{heroDetails?.recognition_title}</h4>
//                       <p>{heroDetails?.name}</p>
//                     </div>
//                   </div>
//                   <div className="card-body p-4 text-black">
//                     <div className="mt-5  text-body">
//                       <div dangerouslySetInnerHTML={{ __html: heroDetails?.recognition_description }} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="post-social-wrap mt-3">
//                 <div className="social-icon-wrap">
//                   <div className="social-icon social-facebook">
//                     <button className="facebook_btn" onClick={shareOnFacebook}>
//                       <i className="fab fa-facebook"></i>
//                       <span>Facebook</span>
//                     </button>
//                   </div>
//                   <div className="social-icon social-pinterest">
//                     <button className="pinterest_btn" onClick={shareOnPinterest}>
//                       <i className="fab fa-pinterest"></i>
//                       <span>Pinterest</span>
//                     </button>
//                   </div>
//                   <div className="social-icon social-linkedin">
//                     <button className="linkedin_btn" onClick={shareOnLinkedIn}>
//                       <i className="fab fa-linkedin"></i>
//                       <span>LinkedIn</span>
//                     </button>
//                   </div>
//                   <div className="social-icon social-twitter">
//                     <button className="twitter_btn" onClick={shareOnTwitter}>
//                       <i className="fab fa-x-twitter"></i>
//                       <span>Twitter</span>
//                     </button>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </div>

//         </section>
//       )}
//     </main>
//   );
// }

// export default HeroDetails;
