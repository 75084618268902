import React, { useEffect } from "react";
import innerBanner from "../../Assets/Images/Event-bg-01-01.jpg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getdonor } from "../../Slice/bloodDonation";
import genericImg from "../../Assets/Images/Generic-img.png";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";
import { stringToNumber } from "../../utils/encrypt";

const DonorCard = () => {
  const { id } = useParams();
  const lastIndex = id.lastIndexOf('-');
  const donorId = id.substring(lastIndex + 1);
  const intID = stringToNumber(donorId);
  const dispatch = useDispatch();
  const { donor, isLoading } = useSelector((state) => state.donor);

  useEffect(() => {
    dispatch(getdonor(intID));
  }, [dispatch, intID]);

  const handleGetInTouch = () => {
    const userEmail = donor?.email;
    const ccEmail = "info@nangalbycycle.com";
    const mailtoLink = `mailto:${userEmail}?cc=${ccEmail}`;
    window.location.href = mailtoLink;
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main bg-light-grey">
          <Helmet>
            <title>Support Nangal By Cycle - Donor Information</title>
            <meta name="description" content="Discover how you can support Nangal By Cycle, a community initiative promoting cycling and sustainability. Learn more about donor details, contributions, and ways to get involved in this eco-friendly movement. Join us in making a difference today!" />
          </Helmet>
          <section className="inner-banner-wrap pb-5">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBanner})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Donor Details</h1>
                </div>
              </div>
            </div>
          </section>

          {donor ? (
            <>
              <div className="col-lg-4"></div>
              <div className="container pt-5 pb-5">
                <div className="main-body">
                  <div className="row gutters-sm">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center text-center">
                            <img
                              src={
                                donor?.userProfile
                                  ? donor?.userProfile
                                  : genericImg
                              }
                              alt="/"
                              className="rounded-circle img-fluid img-thumbnail avatar-img"
                            />
                            <div className="mt-3">
                              <h3>{donor.name}</h3>

                              {/* <p className="text-muted font-size-sm">
                                {donor.addressLine1 + donor.addressLine2}
                              </p> */}
                              <div className="d-grid m-0">
                                <button className="btn btn-outline-primary button-round" type="button" onClick={handleGetInTouch}>Get In Touch</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card mb-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0 fw-bold">Full Name</p>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {donor?.name}
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0 fw-bold">
                                Email
                              </p>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              <span>{donor?.email}</span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0 fw-bold">Blood Group</p>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {" "}
                              {donor?.bloodType}{" "}
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0 fw-bold">Address</p>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {donor?.village}
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>Loading donors...</div>
          )}
        </main>
      )}
    </>
  );
};

export default DonorCard;
