import React, { useEffect, useState } from 'react'
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getPaginatedHeroes } from '../../Slice/heroSlice';
import Loader from '../../Components/Loader';
import { Link } from 'react-router-dom';
import { numberToString } from '../../utils/encrypt';
import { rewriteUrl } from '../../Components/constant/Constant';

function NangalHeroes() {
    const dispatch = useDispatch();

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const [state, setState] = useState({
        search: "",
        page: 1,
        pageSize: 9,
    });
    const { allHeroes, hasMoreHeroes, isLoading } = useSelector((state) => state.hero);

    useEffect(() => {
        dispatch(getPaginatedHeroes(state.search, state.page, state.pageSize));
    }, [dispatch, state.search, state.page, state.pageSize])

    const handleClick = () => {
        setState((prevState) => ({
            ...prevState,
            pageSize: prevState.pageSize + 9,
        }));
    };

    return (
        <main id="content" className="site-main">
            <section className="inner-banner-wrap">
                <div
                    className="inner-baner-container"
                    style={{ backgroundImage: `url(${innerBannerImg1})` }}
                >
                    <div className="container">
                        <div className="inner-banner-content">
                            <h1 className="inner-title">Nangal Heroes</h1>
                        </div>
                    </div>
                </div>
            </section>
            {isLoading ? <Loader /> : (
                <section className="container mb-5 pb-5">
                    <div className="row d-flex justify-content-center mb-4" style={{ gap: '30px' }}>
                        {allHeroes.map((hero, index) => (
                            <div className="col-md-12 col-xl-4 mb-2 hero_person" key={index}>
                                <div className="card hero_inner_card" style={{ borderRadius: "15px" }} >
                                    <div className="card-body text-center px-0">
                                        <div className="mt-3 mb-4">
                                            <img src={hero?.photo_url ? hero?.photo_url : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"} alt='hero-img' className="img-fluid hero_person_img" />
                                        </div>
                                        <h4 className="mb-2 fw-bold">{hero?.name}</h4>
                                        <p className="text-muted mb-4" style={{ padding: '0 48px' }}>{truncateText(hero?.recognition_title, 40)} </p>
                                        <div className="header-btn d-inline-block" style={{ marginTop: hero?.recognition_title ? null : 60 }}>
                                            <Link to={`/nangal-hero/${rewriteUrl(hero?.name)}-${numberToString(hero.hero_id)}`} className="button-round">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {hasMoreHeroes && (
                        <div className="text-center">
                            <button onClick={handleClick} className="button-round">View More</button>
                        </div>
                    )}
                </section>
            )}
        </main>
    )
}

export default NangalHeroes