import React, { useEffect, useState } from "react";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import unboundImg23 from "../../Assets/Images/unbound-img23.jpg";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";
import { getPaginatedEvents } from "../../Slice/events";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Helmet } from "react-helmet";
import { numberToString } from "../../utils/encrypt";
import { rewriteUrl } from "../../Components/constant/Constant";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const currentDateIST = new Date();

function EventArchive() {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));

  const [state, setState] = useState({
    search: "",
    page: 1,
    pageSize: 9,
  });

  useEffect(() => {
    dispatch(getPaginatedEvents(state.page, state.pageSize));
  }, [dispatch, state.page, state.pageSize]);

  const { clientAllEvents, hasMore, isLoading } = useSelector((state) => state.event);

  const formatTime = (time) => {
    return moment(time).format("hh:mm A");
  };
  const formatDate = (day) => {
    return moment(day).format("Do");
  };
  const formatMonth = (month) => {
    return moment(month).format("MMM");
  };


  const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      pageSize: prevState.pageSize + state.pageSize,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      selectedEventId: null,
    }));
  };

  const PopupModal = ({ onClose, eventId }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "8px",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          width: "400px",
          maxWidth: "80%",
          position: "relative",
          animation: "fadeIn 0.5s",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          &times;
        </button>

        <b><p>"It looks like you’re not logged in! Not a member yet? Sign up now to join the event and be part of the experience!".</p></b>
        <button
          style={{
            backgroundColor: "#f15b43",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => ((window.location.href = `/auth/skills?event=${numberToString(eventId)}`))}
        >
          Go to NBC Member Register
        </button>
        <button
          style={{
            backgroundColor: "#f15b43",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => ((window.location.href = `/auth/login?event=${numberToString(eventId)}`))}
        >
          Login
        </button>
      </div>
    </div>
  );

  const handleRegisterClick = (eventId) => {
    if (user) {
      // If user is logged in, redirect to the participation page
      window.location.href = `/event/participation/${numberToString(eventId)}`;
    } else {
      // If user is not logged in, show the modal
      setState((prevState) => ({
        ...prevState,
        showModal: true,
        selectedEventId: eventId,
      }));
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main">
          <Helmet>
            <title>Nangal By Cycle Event Archive | Past Cycling Adventures & Community Events</title>
            <meta name="description" content="Explore our event archive to relive past cycling adventures, community initiatives, and special tours organized by Nangal By Cycle. Get inspired for your next ride!" />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Event List</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="event-page-section">
            <div className="container">
              <div className="row align-items-center">
                {clientAllEvents.map((event) => {
                  const eventExpiryDateTime = new Date(event.endDateTime);
                  const isExpired = eventExpiryDateTime < currentDateIST;

                  const parser = new DOMParser();
                  const htmlDoc = parser.parseFromString(event.description, "text/html");
                  const textElements = htmlDoc.querySelectorAll("p");
                  const text = Array.from(textElements)
                    ?.map((p) => p.innerText)
                    .join(" ");

                  return (
                    <div className="col-lg-4" key={event.eventId}>
                      <article
                        className={`event-item event-item-bg ${isExpired ? 'expired' : ''}`}
                        style={{
                          backgroundImage: `url(${event?.imageUrl})`,
                        }}
                      >
                        <div className="event-date primary-bg">
                          <h4>{formatDate(event.startDateTime)}</h4>
                          <h5>{formatMonth(event.startDateTime)}</h5>
                        </div>
                        <div className="event-content">
                          <div>
                            <h3>{event.title}</h3>
                            <div className="event-meta">
                              <span className="time-meta">
                                <i className="icon icon-clock1"></i>
                                {formatTime(event.startDateTime)} - {formatTime(event.endDateTime)}
                              </span>
                              <span>
                                <i className="icon icon-map-marker1"></i>
                                {event.location}
                              </span>
                            </div>
                            <div className="react_quill_editor">{truncateText(text, 200)}</div>
                          </div>
                          <div className="btn-wrap">
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                              <Link
                                to={`/event/${rewriteUrl(event?.title)}-${numberToString(event?.eventId)}`}
                                className="outline-round outline-round-white"
                              >
                                View Details
                              </Link>
                              {!isExpired  && event?.allowParticipants === 1 && (
                                <button
                                  onClick={() => handleRegisterClick(event.eventId)}
                                  className="button-round event_block_padding"
                                >
                                  Register
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="overlay"></div>
                      </article>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          {hasMore && (
            <div className="text-center">
              <button onClick={handleClick} className="button-round">View More</button>
            </div>
          )}
          <br />
          <div
            className="callback-inner-page"
            style={{ backgroundImage: `url(${unboundImg23})` }}
          >
            <div className="container">
              <div className="callback-content text-center">
                <div className="button-wrap">
                  <Link to="/become-volunteer" className="button-round">
                    Get Involved Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="overlay"></div>
          </div>
        </main>
      )}
      {state.showModal && <PopupModal onClose={closeModal} eventId={state.selectedEventId} />}
    </>
  );
}

export default EventArchive;
