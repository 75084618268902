import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import Loginbg from "../../Assets/Images/Loginbg.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { emailrgx } from "../../Components/constant/Constant";
import PhoneInput from "react-phone-input-2";
import "react-datepicker/dist/react-datepicker.css";
import { addEventParticipant, getEvent } from "../../Slice/events";
import Loader from "../../Components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { stringToNumber } from "../../utils/encrypt";

const EventParticipationForm = () => {

    const [previewUrl, setPreviewUrl] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const intID = stringToNumber(id);

    useEffect(() => {
        dispatch(getEvent(intID));
    }, [intID, dispatch]);

    const { event, isLoading } = useSelector((state) => state.event);

    const schema = yup
        .object({
            name: yup.string().required("Name is required").trim(),
            email: yup
                .string()
                .required("Email is required")
                .matches(emailrgx, "Invalid Email")
                .trim(),
            contact: yup.string().required("Phone Number is required").max(12).min(10),
            upload: yup
                .mixed()
                .test(
                    "Profile photo is required",
                    function (value) {
                        if (event?.requireUpload === 1 && !value) {
                            return false;
                        }
                        return true;
                    }
                )
                .test("fileType", "Invalid file type", (value) => {
                    if (!value) return true; 
                    return ["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(value?.type);
                }),
        });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate();

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("name", data?.name);
        formData.append("email", data?.email);
        formData.append("contact", data?.contact);
        formData.append("event", intID);
        formData.append("upload", data?.upload)
        dispatch(addEventParticipant(formData, reset, navigate));
        console.log(data)
        setPreviewUrl("")
    };
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <main id="content" className="site-main">
                    <section className="inner-banner-wrap pb-0">
                        <div
                            className="inner-baner-container"
                            style={{ backgroundImage: `url(${innerBannerImg1})` }}
                        >
                            <div className="container">
                                <div className="inner-banner-content">
                                    <h1 className="inner-title">Register For Event</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div
                        className="volunteer-wrap"
                        style={{ backgroundImage: `url(${Loginbg})` }}
                    >
                        <div className="container">
                            <div className="row pt-5">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="volunteer-contact-form">
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            className="volunteer-form"
                                        >
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <label className="text-left">
                                                        Name<span style={{ color: "red" }}> *</span>
                                                    </label>
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`input_fixed_width ${errors?.name ? "valid_error" : ""
                                                                    }`}
                                                                placeholder="Full Name"
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="right"
                                                                title="As per Aadhar card or passport"
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.name && (
                                                        <div style={{ color: "red" }} className="text-left">
                                                            {errors?.name?.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <label className="text-left">
                                                        Registered Email <span style={{ color: "#F15B43" }}>*</span>
                                                    </label>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                placeholder="Email Address"
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`input_fixed_width ${errors?.email ? "valid_error" : ""
                                                                    }`}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.email && (
                                                        <div style={{ color: "red" }} className="text-left">
                                                            {" "}
                                                            {errors?.email.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <label className="text-left">
                                                        Phone Number{" "}
                                                        <span style={{ color: "#F15B43" }}>*</span>
                                                    </label>
                                                    <Controller
                                                        name="contact"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <PhoneInput
                                                                className={`${errors?.contact ? "valid_error" : ""
                                                                    }`}
                                                                country={"in"}
                                                                value={value}
                                                                onChange={(phone) => onChange(phone)}
                                                                style={{
                                                                    border: errors?.contact
                                                                        ? "1px solid red"
                                                                        : "",
                                                                }}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.contact && (
                                                        <div style={{ color: "red" }} className="text-left">
                                                            {" "}
                                                            {errors?.contact?.message}
                                                        </div>
                                                    )}
                                                </div>
                                                {event?.requireUpload === 1 && (
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                                                        <div className="mb-2 profile-photo-class">
                                                            Upload Picture <span style={{ color: "red" }}> *</span>
                                                        </div>
                                                        <label
                                                            htmlFor="upload"
                                                            className="input_fixed_width"
                                                            style={{
                                                                lineHeight: 3,
                                                                border: errors?.upload
                                                                    ? "1px solid red"
                                                                    : "1px solid #B8BDC9",
                                                            }}
                                                        >
                                                            Upload Photo or PDF
                                                        </label>
                                                        <Controller
                                                            name="upload"
                                                            control={control}
                                                            render={({ field: { onChange, onBlur } }) => (
                                                                <>
                                                                    <input
                                                                        id="upload"
                                                                        type="file"
                                                                        className="file-input-class"
                                                                        onBlur={onBlur}
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file) {
                                                                                if (file.type.includes("pdf")) {
                                                                                    setPreviewUrl(null);
                                                                                } else {
                                                                                    setPreviewUrl(URL.createObjectURL(file));
                                                                                }
                                                                                setUploadedFile(file);
                                                                            } else {
                                                                                setPreviewUrl("");
                                                                                setUploadedFile(null);
                                                                            }
                                                                            onChange(file);
                                                                        }}
                                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                                    />

                                                                    {previewUrl ? (
                                                                        <img
                                                                            src={previewUrl}
                                                                            alt="Profile Preview"
                                                                            className="img-thumbnail mt-2"
                                                                            style={{ maxWidth: "150px", height: "auto" }}
                                                                        />
                                                                    ) : uploadedFile && uploadedFile.type === "application/pdf" ? (
                                                                        <p className="mt-2">PDF uploaded: {uploadedFile.name}</p>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        />
                                                        {errors?.upload && (
                                                            <div style={{ color: "red" }} className="text-left">
                                                                {errors?.upload?.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="submit-area col-lg-12 col-12">
                                                <button
                                                    style={{ borderRadius: 6 }}
                                                    className="button-round"
                                                    type="submit"
                                                >
                                                    Register
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </main >
            )}
        </>
    )
}

export default EventParticipationForm