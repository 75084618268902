import React, { useRef, useState } from 'react';
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getECertificates } from '../Slice/events';
import jsPDF from 'jspdf';
import certificate from "../Assets/Images/Certificate.png";
import Loader from '../Components/Loader';
import moment from 'moment';
import { Table } from 'antd';

function ECertificates() {
  const [email, setEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [certsPerPage] = useState(5);
  const dispatch = useDispatch();
  const { userCertificates, isLoading } = useSelector((state) => state.event);

  const handleDownload = (index) => {
    const cert = userCertificates[index];
    const filename = `certificate_${index + 1}`;

    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();  // Get the PDF width
    const pdfHeight = pdf.internal.pageSize.getHeight(); // Get the PDF height

    // Get the original certificate dimensions (adjust these based on your actual image size)
    const originalWidth = 3616; // Example width in pixels
    const originalHeight = 2552; // Example height in pixels

    // Calculate aspect ratio
    const aspectRatio = originalWidth / originalHeight;

    // Determine the new width and height while maintaining aspect ratio
    let imgWidth = pdfWidth;
    let imgHeight = pdfWidth / aspectRatio;

    // If the calculated height exceeds the PDF height, adjust width and height
    if (imgHeight > pdfHeight) {
      imgHeight = pdfHeight;
      imgWidth = pdfHeight * aspectRatio;
    }

    // Add the certificate image to the PDF without stretching it
    const x = (pdfWidth - imgWidth) / 2; // Center horizontally
    const y = (pdfHeight - imgHeight) / 2; // Center vertically
    pdf.addImage(certificate, 'JPEG', x, y, imgWidth, imgHeight);

    pdf.setFontSize(9);
    const certNumber = cert.certificate_no || "CERT-N/A";
    pdf.text(certNumber, pdfWidth - 54, 90.5);

    pdf.setFontSize(12);
    const Signature = "Rohit Garg";
    pdf.text(Signature, pdfWidth - 73, 200);

    pdf.setFontSize(20);
    const name = cert.name;
    pdf.text(name, pdfWidth / 2, 165, { align: 'center' });

    // Center-align the event name
    pdf.setFontSize(12);
    const eventName = cert.eventName;
    pdf.text(eventName, pdfWidth / 2, 179, { align: 'center' });

    // Set the font and alignment for the date
    pdf.setFontSize(12);
    const date = moment(cert.endDateTime).format("DD/MM/YYYY");
    pdf.text(date, pdfWidth - 161, 200);

    pdf.save(`${filename}.pdf`);
  };

  const handleView = (index) => {
    const cert = userCertificates[index];
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();  // Get the PDF width
    const pdfHeight = pdf.internal.pageSize.getHeight(); // Get the PDF height

    // Get the original certificate dimensions (adjust these based on your actual image size)
    const originalWidth = 3616; // Example width in pixels
    const originalHeight = 2552; // Example height in pixels

    // Calculate aspect ratio
    const aspectRatio = originalWidth / originalHeight;

    // Determine the new width and height while maintaining aspect ratio
    let imgWidth = pdfWidth;
    let imgHeight = pdfWidth / aspectRatio;

    // If the calculated height exceeds the PDF height, adjust width and height
    if (imgHeight > pdfHeight) {
      imgHeight = pdfHeight;
      imgWidth = pdfHeight * aspectRatio;
    }

    const x = (pdfWidth - imgWidth) / 2;
    const y = (pdfHeight - imgHeight) / 2;
    pdf.addImage(certificate, 'JPEG', x, y, imgWidth, imgHeight);

    pdf.setFontSize(9);
    const certNumber = cert.certificate_no || "CERT-N/A";
    pdf.text(certNumber, pdfWidth - 54, 90.5);

    pdf.setFontSize(12);
    const Signature = "Rohit Garg";
    pdf.text(Signature, pdfWidth - 73, 200);

    pdf.setFontSize(20);
    const name = cert.name;
    pdf.text(name, pdfWidth / 2, 165, { align: 'center' });

    // Center-align the event name
    pdf.setFontSize(12);
    const eventName = cert.eventName;
    pdf.text(eventName, pdfWidth / 2, 179, { align: 'center' });

    // Set the font and alignment for the date
    pdf.setFontSize(12);
    const date = moment(cert.endDateTime).format("DD/MM/YYYY");
    pdf.text(date, pdfWidth - 161, 200);

    const pdfBlob = pdf.output('blob');
    const pdfURL = URL.createObjectURL(pdfBlob);
    window.open(pdfURL, '_blank'); // Open the PDF in a new tab
  };

  const fetchCertificates = async () => {
    try {
      dispatch(getECertificates({ email: email }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchCertificates();
  };

  const indexOfLastCert = currentPage * certsPerPage;

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className="btn btn-sm btn-primary">Previous</button>;
    }
    if (type === 'next') {
      return <button className="btn btn-sm btn-primary">Next</button>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Event',
      dataIndex: 'eventName',
      sorter: (a, b) => a.eventName.length - b.eventName.length,
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
          <Button onClick={() => handleDownload(index)} className="button-round border_radius">
            Download PDF
          </Button>
          <Button onClick={() => handleView(index)} className="button-round border_radius">
            View PDF
          </Button>
        </div>
      ),
    },
  ]

  return (
    <main id="content" className="site-main">
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: `url(${innerBannerImg1})` }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h1 className="inner-title">Certificates</h1>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <Loader />
      ) : (
        <Container className="my-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail">
                  <Form.Label><b>Enter your email to view certificates <span style={{ color: "red" }}>*</span></b></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <br />
                <Button type="submit" className="button-round border_radius">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
          <br />
          <Table
            pagination={{
              // current: state.page,
              // pageSize: state.pagesize,
              // total: totalCount,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              showSizeChanger: true,
              // onShowSizeChange: onShowSizeChange,
              itemRender: itemRender,
              // onChange: (page, pageSize) =>
              //   setState({ ...state, page, pagesize: pageSize }),
            }}
            bordered
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={userCertificates}
            rowKey={(record) => record.donorId}
          />
        </Container>
      )}
    </main>
  );
}

export default ECertificates;
