import React, { useEffect } from "react";
import * as yup from "yup";
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { getAllFaqs, submitFaqQuestion } from "../Slice/faq";
import { Accordion } from "react-bootstrap";
import { getUserInfoFromToken } from "../Components/constant/Constant";
import { Helmet } from "react-helmet";

const schema = yup
  .object({
    question: yup.string().required("Field is empty").trim(),
  })
  .required();

function FAQ() {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const state = {
    search: "",
    selectedVillage: "",
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const reloadData = async () => {
      dispatch(getAllFaqs(state.search, state.page, state.pageSize, state.selectedVillage));
    };
    reloadData();
  }, [dispatch, state.page, state.pageSize]);

  const onSubmit = async (data) => {
    var userInfo = getUserInfoFromToken();
    const requestData = {
      question: data?.question,
      createdBy: userInfo?.userId
    };
    dispatch(submitFaqQuestion(requestData, reset));
  };

  return (
    <>
      <main id="content" className="site-main">
        <Helmet>
          <title>Frequently Asked Questions | Nangal by Cycle</title>
          <meta name="description" content="Find answers to common questions about Nangal by Cycle, our events, donation process, and more. Get the information you need to join and support our cause." />
        </Helmet>
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: `url(${innerBannerImg1})` }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Faq</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="faq-page-section">
          <div className="container">
            <div className="faq-page-container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="section-head">
                    <div className="sub-title">ANY QUESTIONS</div>
                    <h2 className="section-title">
                      FREQUENTLY ASKED QUESTIONS
                    </h2>
                    <p>
                      It is common knowledge that any business that wants to succeed must provide excellent customer service. Customer experience, according to 95% of consumers, is essential for retaining a company.
                      Regretfully, providing a first-rate client experience costs a lot of money and effort, which makes it difficult for a company to do.
                    </p>
                  </div>
                  <Accordion defaultActiveKey="0" className="accordion-content w-100">
                    <Accordion.Item eventKey="0" className="card tab-pane">
                      <Accordion.Header className="card-header">
                        <h4>What is Nangal famous for?</h4>
                      </Accordion.Header>
                      <Accordion.Body className="card-body">
                        <div>
                          <p>Nangal is renowned for several key features:</p>
                          <ul>
                            <li>
                              <strong>Bhakra Nangal Dam: </strong><p>One of the world's highest gravity dams, it is a major tourist attraction and vital source of hydroelectric power and irrigation for northern India.</p>
                            </li>
                            <li>
                              <strong>Natural Beauty: </strong><p>Located near the Satluj River, Nangal is known for its picturesque landscapes and lush greenery.</p>
                            </li>
                            <li>
                              <strong>Educational and Industrial Hub: </strong><p>Nangal houses important industrial units and educational institutions, contributing significantly to the local economy.</p>
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="card tab-pane">
                      <Accordion.Header className="card-header">
                        <h4>How old is Nangal?</h4>
                      </Accordion.Header>
                      <Accordion.Body className="card-body">
                        <p>
                          Nangal's history is closely tied to the construction of the Bhakra Nangal Dam, which began in the 1940s. Before this period, Nangal was a small, less-known village. The town gained prominence with the construction of the dam, which brought in workers, engineers, and their families, leading to its rapid development. Today, Nangal is a well-established town with a rich history of contributing to India's infrastructure and economy.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="card tab-pane">
                      <Accordion.Header className="card-header">
                        <h4>What is the weather like in Nangal?</h4>
                      </Accordion.Header>
                      <Accordion.Body className="card-body">
                        <div>
                          <p>Nangal experiences a subtropical climate with the following seasonal patterns:</p>
                          <ul>
                            <li>
                              <strong>Summer (April to June): </strong><p>Hot and dry, with temperatures ranging from 25°C to 40°C (77°F to 104°F).</p>
                            </li>
                            <li>
                              <strong>Monsoon (July to September): </strong><p>Warm and humid, with significant rainfall, cooling the temperatures slightly.</p>
                            </li>
                            <li>
                              <strong>Winter (October to March): </strong><p>Mild and pleasant, with temperatures ranging from 5°C to 20°C (41°F to 68°F).</p>
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="col-lg-6">
                  <div className="qsn-form-container">
                    <h3>Still have a questions ?</h3>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullam corper
                    </p> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <p>
                        <Controller
                          name="question"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Your question*"
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.question && (
                          <p> {errors?.question?.message}</p>
                        )}
                      </p>
                      <p>
                        <input
                          type="submit"
                          name="submit"
                          value="SUBMIT QUESTIONS"
                        />
                      </p>
                    </form>
                  </div>
                  <div className="mt-4">
                    <Accordion defaultActiveKey="0" className="accordion-content w-100">
                      <Accordion.Item eventKey="1" className="card tab-pane">
                        <Accordion.Header className="card-header">
                          <h4>What are the top things to do in Nangal?</h4>
                        </Accordion.Header>
                        <Accordion.Body className="card-body">
                          <div>
                            <p>Top attractions and activities in Nangal include:</p>
                            <ul>
                              <li>
                                <strong>Visit the Bhakra Nangal Dam: </strong><p>Explore the engineering marvel and enjoy the scenic beauty of the area.</p>
                              </li>
                              <li>
                                <strong>Satluj Park: </strong><p>A beautiful park perfect for family outings and picnics.</p>
                              </li>
                              <li>
                                <strong>Boating on Gobind Sagar Lake: </strong><p>Enjoy water sports and boating activities on the reservoir created by the dam.</p>
                              </li>
                              <li>
                                <strong>Local Markets: </strong><p>Experience the vibrant local culture and shop for traditional Punjabi goods.</p>
                              </li>
                              <li>
                                <strong>Historical Sites: </strong><p>Visit nearby historical and religious sites, including Gurudwaras and temples.</p>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="card tab-pane">
                        <Accordion.Header className="card-header">
                          <h4>What is the local language spoken in Nangal?</h4>
                        </Accordion.Header>
                        <Accordion.Body className="card-body">
                          <p>
                            The primary language spoken in Nangal is Punjabi. However, Hindi and English are also commonly understood and used, especially in educational and business contexts.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="0" className="card tab-pane">
                        <Accordion.Header className="card-header">
                          <h4>How to verify your account?</h4>
                        </Accordion.Header>
                        <Accordion.Body className="card-body">
                          <p>
                            To verify your account after registering, follow these steps:
                          </p>
                          <ol>
                            <li>
                              <strong>Complete the registration process:</strong> After filling out the registration form, click the "Register" button.
                            </li>
                            <br/>
                            <li>
                              <strong>Receive the OTP:</strong> An OTP (One-Time Password) will be sent to the email address you entered during the registration process. This OTP is valid for <strong>36 hours</strong>.
                            </li>
                            <br/>
                            <li>
                              <strong>Open the verification email:</strong> Check your inbox (and spam/junk folder, if necessary) for an email containing the OTP. The email will include a <strong>"Verify OTP"</strong> button.
                            </li>
                            <br/>
                            <li>
                              <strong>Click the "Verify OTP" button:</strong> Clicking this button will redirect you to the OTP verification page.
                            </li>
                            <br/>
                            <li>
                              <strong>Enter the OTP:</strong> On the verification page, enter the OTP you received in your email. Double-check for any spaces or typos while entering the OTP.
                            </li>
                            <br/>
                            <li>
                              <strong>Submit the OTP:</strong> After entering the OTP, click the "Submit" button to complete your account verification.
                            </li>
                            <br/>
                            <li>
                              <strong>In case of an expired OTP:</strong> If the OTP has expired (after 36 hours), click the <strong>"Resend OTP"</strong> button on the verification page. A new OTP will be generated and sent to your email. Follow the same steps to verify your account using the new OTP.
                            </li>
                          </ol>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default FAQ;
