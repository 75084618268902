import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVolunteers } from "../Slice/volunteers";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Masonry from "masonry-layout";
import HomeTestimonialSlider from "../Components/Slider/HomeTestimonialSlider";
import Fancybox from "../Components/FancyBox";
import Loader from "../Components/Loader";
import ClientSlider from "../Components/Slider/ClientSlider";
import unboundImg2 from "../Assets/Images/unbound-img2.jpg";
import unboundImg4 from "../Assets/Images/vecteezy_healthcare-professional.jpg";
import unboundImg3 from "../Assets/Images/Nagal-photo.png";
import unbountImg23 from "../Assets/Images/unbound-img23.jpg";
import vaweimg from "../Assets/Images/vawe-img.png";
import volunteerBackImg from '../Assets/Images/Volunteer.jpg';
import bloodDonationBackImg from '../Assets/Images/Blood-donation.jpg';
import genericImg from "../Assets/Images/Generic-img.png";
import founderImg from "../Assets/Images/founder-img.jpg";
import LakshyaImg from "../Assets/Images/Lakshya-Garg.png";
import lavanyaGargImage from "../Assets/Images/Lavanya-Garg.JPG";
import { getAllEvents } from "../Slice/events";
import { getPaginatedImages } from "../Slice/gallery";
import { getPaginatedBlogs } from "../Slice/blogs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import { addrequest } from "../Slice/contactRequest";
import { FaBullseye, FaUser } from "react-icons/fa";
import { BsCalendar2DateFill } from "react-icons/bs";
import * as yup from "yup";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import nbcArt from '../Assets/Images/Nangal-by-CycleArt.png';
import { Helmet } from "react-helmet";
import { getCommunityStats } from "../Slice/master";
import HomeBannerSlider from "../Components/Slider/HomeBannerSlider";
import { emailrgx, rewriteUrl } from "../Components/constant/Constant";
import { numberToString } from "../utils/encrypt";
import { Carousel } from "react-bootstrap";

const schema = yup
    .object({
        name: yup.string().required("Name is required").trim(),
        email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        subject: yup.string().required("Subject is required").trim(),
        phone: yup
            .number()
            .typeError("Contact must be a number")
            .required("Phone Number is required")
            .min(100000000000, "Phone number must be less than digits 12"),
        description: yup.string().required("Description is required").trim(),
    })
    .required();

const formatEventDesc = (desc) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(desc, "text/html");
    const textElements = htmlDoc.querySelectorAll("p");
    const text = Array.from(textElements)
        ?.map((p) => p.innerText)
        .join(" ");
    return text;
}

function Home() {
    const dispatch = useDispatch();
    const { galleryImages } = useSelector((state) => state.image);
    const { blogsList } = useSelector((state) => state.blog);
    const { users, isLoading } = useSelector((state) => state.user);
    const { homeContactLoading } = useSelector((state) => state.contact);
    const { homeAllDonorsCount, homeAllVolunteersCount, homeAllEventsCount, homeAllHeroesCount, homeAllMembersCount } = useSelector((state) => state.masterSlice);
    const { events } = useSelector((state) => state.event);
    const [widthPercentage, setWidthPercentage] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const state = { search: "", page: 1, pageSize: 6 };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const reloadData = useCallback(() => {
        const isPublished = true;
        dispatch(getAllVolunteers(state.search, state.page, state.pageSize));
        dispatch(getAllEvents(state.search, state.page, state.pageSize));
        dispatch(getPaginatedImages(state.search, state.page, 12, null));
        dispatch(getPaginatedBlogs(state.search, state.page, 6, null, isPublished));
        dispatch(getCommunityStats());
    }, [dispatch, state.search, state.page, state.pageSize]);

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    useEffect(() => {
        const counts = [homeAllVolunteersCount, homeAllDonorsCount, homeAllHeroesCount, homeAllEventsCount, homeAllMembersCount];
        const nonZeroCounts = counts.filter(count => count > 0).length;
        const width = nonZeroCounts > 0 ? 100 / nonZeroCounts : 0;
        setWidthPercentage(width);
        setIsLoaded(true);
    }, [homeAllVolunteersCount, homeAllDonorsCount, homeAllHeroesCount, homeAllEventsCount, homeAllMembersCount])

    useEffect(() => {
        function masonryLayout() {
            const grid = document.querySelector(".grid");
            new Masonry(grid, {
                itemSelector: ".grid-item",
            });
        }
        const delay = 4000;
        const timeoutId = setTimeout(masonryLayout, delay);
        return () => clearTimeout(timeoutId);
    }, []);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const formatTime = (time) => moment(time).format("hh:mm A");
    const formatDate = (day) => moment(day).format("Do");
    const formatMonth = (month) => moment(month).format("MMM");
    const formatDateblog = (day) => moment(day).format("MMMM DD, YYYY");

    const onSubmit = async (data) => {
        const requestData = {
            name: data?.name,
            email: data?.email,
            subject: data?.subject,
            phone: data?.phone,
            description: data?.description,
        };
        dispatch(addrequest(requestData, reset));
    };

    if (!isLoaded) {
        return null;
    }


    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <main id="content" className="site-main">
                    <Helmet>
                        <title>Nangal By Cycle - Explore Nangal on Two Wheels | Bicycle Tours & Rentals</title>
                        <meta name="description" content="Discover Nangal's beauty through eco-friendly bicycle tours and rentals. Experience local culture, scenic routes, and adventure with Nangal By Cycle." />
                    </Helmet>
                    <HomeBannerSlider />

                    <section className="home-donate bg_dark_2 py-5 mb-5" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                        <div className="container">
                            <div className="section-head text-center">
                                <div className="sub-title">GET INVOLVED NOW</div>
                                <h2 className="section-title">Make a difference today</h2>
                            </div>
                            <div className="donate-inner">
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                                        <article
                                            className="donate-content bg-transparent-img"
                                            style={{ backgroundImage: `url(${unboundImg2})` }}
                                        ><div className="custom-text">
                                                <h3>
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon icon-hand_shake"
                                                    ></i>
                                                    Our Mission
                                                </h3>
                                                <p>
                                                    To empower individuals, strengthen community bonds, and
                                                    foster a culture of support and collaboration. Join us
                                                    in building a vibrant and resilient community where
                                                    everyone thrives. Together, we can make a difference,
                                                    one connection at a time.
                                                </p>
                                                <Link to="/about" className="button-round">
                                                    Learn More
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                                        <article
                                            className="donate-content bg-transparent-img"
                                            style={{ backgroundImage: `url(${volunteerBackImg})` }}
                                        ><div className="custom-text">
                                                <h3>
                                                    <i aria-hidden="true" className="icon icon-user1"></i>
                                                    Volunteer Opportunities
                                                </h3>
                                                <p>
                                                    Get involved and make a difference in your community!
                                                    Browse through various volunteer opportunities ranging
                                                    from environmental initiatives to educational programs.
                                                    Sign up, lend a hand, and be part of positive change.
                                                </p>
                                                <Link to="/become-volunteer" className="button-round">
                                                    Join Us Now
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-sm-6 col-lg-4" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                                        <article
                                            className="donate-content bg-transparent-img"
                                            style={{ backgroundImage: `url(${bloodDonationBackImg})` }}
                                        ><div className="custom-text">
                                                <h3>
                                                    <i
                                                        aria-hidden="true"
                                                        className="icon icon-save-money"
                                                    ></i>
                                                    Blood Donor Registration
                                                </h3>
                                                <p>
                                                    Every drop counts. Register as a blood donor and help
                                                    save lives. Our platform facilitates easy registration
                                                    and connects donors with those in need, ensuring timely
                                                    access to blood donations within our community.
                                                </p>
                                                <Link
                                                    to="/become-donor"
                                                    className="button-round"
                                                >
                                                    Registration
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <img src={vaweimg} alt="vaweimg" className="w-100 position-absolute bottom-0" style={{ filter: 'drop-shadow(2px 4px 0 white)' }} />
                    </section>
                    <section className="home-about home-about-section py-5" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="home-about-left">
                                        <div className="home-about-image">
                                            <Carousel indicators={false} controls={false} slide={true} interval={4000}>
                                                {galleryImages?.slice(0, 5).map((image, index) => (
                                                    <Carousel.Item key={index}>
                                                        <img
                                                            src={image?.image_url} // Assuming each image object has an image_url property
                                                            alt={`Gallery Image ${index + 1}`}
                                                            style={{
                                                                width: '1100px',  // Set your desired width
                                                                height: '500px', // Set your desired height
                                                                objectFit: 'cover', // Ensures the image covers the area without distortion
                                                            }}
                                                            className="d-block" // Keep Bootstrap's d-block class for display
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        </div>
                                        <div className="counter-wrap">
                                            {/* Your existing counter code */}
                                            {homeAllVolunteersCount > 0 && (
                                                <div className="counter-item" style={{ width: `${widthPercentage}%` }}>
                                                    <span className="counter-no">
                                                        <span className="counter">{homeAllVolunteersCount ? homeAllVolunteersCount : 0}</span>
                                                    </span>
                                                    <span className="counter-text">Volunteers</span>
                                                </div>
                                            )}
                                            {homeAllDonorsCount > 0 && (
                                                <div className="counter-item" style={{ width: `${widthPercentage}%` }}>
                                                    <span className="counter-no">
                                                        <span className="counter">{homeAllDonorsCount ? homeAllDonorsCount : 0}</span>
                                                    </span>
                                                    <span className="counter-text">Donors</span>
                                                </div>
                                            )}
                                            {homeAllMembersCount > 0 && (
                                                <div className="counter-item" style={{ width: `${widthPercentage}%` }}>
                                                    <span className="counter-no">
                                                        <span className="counter">{homeAllMembersCount ? homeAllMembersCount : 0}</span>
                                                    </span>
                                                    <span className="counter-text">Members</span>
                                                </div>
                                            )}
                                            {homeAllHeroesCount > 0 && (
                                                <div className="counter-item" style={{ width: `${widthPercentage}%` }}>
                                                    <span className="counter-no">
                                                        <span className="counter">{homeAllHeroesCount}</span>
                                                    </span>
                                                    <span className="counter-text">Our Heroes</span>
                                                </div>
                                            )}
                                            {homeAllEventsCount > 0 && (
                                                <div className="counter-item" style={{ width: `${widthPercentage}%` }}>
                                                    <span className="counter-no">
                                                        <span className="counter">{homeAllEventsCount ? homeAllEventsCount : 0}</span>
                                                    </span>
                                                    <span className="counter-text">Events</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="home-contact"
                        style={{ backgroundImage: `url(${unboundImg4})` }}
                    >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="sub-title">Health Campaigns</div>
                                    <h2 className="section-title">
                                        Health Awareness and Best Practices
                                    </h2>
                                    <p>
                                        Stay informed and prioritize your well-being with our health
                                        awareness resources. Access articles, guides, and tips on
                                        maintaining a healthy lifestyle, preventing diseases, and
                                        fostering mental well-being. Empower yourself and your
                                        community with knowledge and best practices for a healthier
                                        tomorrow.
                                    </p>
                                </div>
                                <div className="col-lg-6" data-aos="fade-left" data-aos-anchor-placement="top-center" data-aos-duration="3000">
                                    {homeContactLoading ? <Loader /> : (
                                        <div className="home-contact-form">
                                            <form className="contact-form row" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="col-12 mb-3">
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="form-control mb-2"
                                                                placeholder="Your Name*"
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.name && (
                                                        <p style={{ color: "red", fontSize: 13, backgroundColor: 'white', borderRadius: 2, display: "inline", padding: '0 4px' }}>
                                                            {" "}
                                                            {errors?.name?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="form-control mb-2"
                                                                placeholder="Your Email*"
                                                                type="email"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.email && (
                                                        <p style={{ color: "red", fontSize: 13, backgroundColor: '#F8F8F8', borderRadius: 2, display: "inline", padding: '0 4px' }}>{errors?.email?.message}</p>
                                                    )}
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <Controller
                                                        name="subject"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="form-control mb-2"
                                                                placeholder="Subject*"
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.subject && (
                                                        <p style={{ color: "red", fontSize: 13, backgroundColor: '#F8F8F8', borderRadius: 2, display: "inline", padding: '0 4px' }}>{errors?.subject?.message}</p>
                                                    )}
                                                </div>
                                                <div className="col-sm-6 mb-3">
                                                    <Controller
                                                        name="phone"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <PhoneInput
                                                                className='mt-phone'
                                                                country={"in"}
                                                                value={value}
                                                                onChange={(phone) => onChange(phone)}
                                                                inputStyle={{ width: "100%" }}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.phone && (
                                                        <p style={{ color: "red", fontSize: 13, backgroundColor: '#F8F8F8', borderRadius: 2, display: "inline", padding: '0 4px' }}>{errors?.phone?.message}</p>
                                                    )}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <Controller
                                                        name="description"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <textarea
                                                                className="form-control mb-2"
                                                                rows={8}
                                                                placeholder="Enter Your Message*"
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.description && (
                                                        <p style={{ color: "red", fontSize: 13, backgroundColor: '#F8F8F8', borderRadius: 2, display: "inline", padding: '0 4px' }}>{errors?.description?.message}</p>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <button style={{ borderRadius: 6 }} type="submit" className="button-round">
                                                        Get Involved Today
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="overlay"></div>
                    </section>
                    <div className="home-client">
                        <div className="container">
                            <div className="client-wrap client-slider text-center">
                                <ClientSlider />
                            </div>
                        </div>
                    </div>
                    {events && events.length > 0 && (
                        <section className="home-event bg_dark">
                            <div className="container">
                                <div className="section-head text-center">
                                    <div className="sub-title">INVOLVE NOW</div>
                                    <h2 className="section-title">Upcoming Events</h2>
                                </div>
                                <div className="event-inner">
                                    <div className="row align-items-center">
                                        {events.map((event) => (
                                            <div className="col-lg-4" data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-duration="1000" key={event.eventId}>
                                                <article className="event-item event-item-bg"
                                                    style={{
                                                        backgroundImage: `url(${event?.imageUrl})`,
                                                    }}>
                                                    <div className="event-date primary-bg">
                                                        <h4>{formatDate(event.startDateTime)}</h4>
                                                        <h5>{formatMonth(event.startDateTime)}</h5>
                                                    </div>
                                                    <div className="event-content">
                                                        <div style={{}}>
                                                            <h3>{event.title}</h3>
                                                            <div className="event-meta">
                                                                <span className="time-meta">
                                                                    <i className="icon icon-clock1"></i>
                                                                    {formatTime(event.startDateTime)} -{" "}
                                                                    {formatTime(event.endDateTime)}
                                                                </span>
                                                                <span>
                                                                    <i className="icon icon-map-marker1"></i>
                                                                    {event.location}
                                                                </span>
                                                            </div>
                                                            <p>{truncateText(formatEventDesc(event.description), 200)}</p>
                                                        </div>
                                                        <div className="btn-wrap">
                                                            <Link
                                                                to={`/event/${rewriteUrl(event?.title)}-${numberToString(event?.eventId)}`}
                                                                className="outline-round outline-round-white"
                                                            >
                                                                View Details
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="overlay"></div>
                                                </article>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="list-more-btn text-center">
                                        <Link to="/events" className="button-round">
                                            Discover All Events
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    {users && users.length > 0 && (
                        <section className="home-team bg_dark_2 py-5">Our work promise to uphold the trust placed
                            <div className="container">
                                <div className="section-head text-center">
                                    <div className="sub-title">MEMBERS / VOLUNTEERS</div>
                                    <h2 className="section-title">Our optimistic volunteer</h2>
                                </div>
                                <div className="team-inner">
                                    <div className="row">
                                        {users.slice(0, 2)?.map((element) => (
                                            <div className="col-lg-6" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000" key={element.volunteerId}>
                                                <article className="team-item">
                                                    <figure className="team-img">
                                                        <img
                                                            src={
                                                                element.imageUrl
                                                                    ? element.imageUrl
                                                                    : genericImg
                                                            }
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <div className="team-content">
                                                        <h3>{element?.fullName}</h3>
                                                        <span>{element?.profession}</span>
                                                        <span><i className="me-1 fas fa-map-marker-alt" style={{
                                                            color: '#F15B43'
                                                        }}></i>{element?.village}</span>
                                                        <span>
                                                            <div className="d-flex">
                                                                <i className="me-1 fas fa-envelope" style={{ color: '#CB4B36' }}>
                                                                </i>
                                                                <Link to="#" className="text-break">
                                                                    {element?.email}
                                                                </Link>
                                                            </div>
                                                        </span>
                                                        <div className="social-links">
                                                            <ul>
                                                                {element?.facebook && (
                                                                    <li>
                                                                        <a href={element.facebook}>
                                                                            <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {element?.twitter && (
                                                                    <li>
                                                                        <a href={element.twitter}>
                                                                            <i className="fab fa-twitter" aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {element?.youtube && (
                                                                    <li>
                                                                        <a href={element.youtube}>
                                                                            <i className="fab fa-youtube" aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {element?.Instagram && (
                                                                    <li>
                                                                        <a href={element.Instagram}>
                                                                            <i className="fab fa-instagram" aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {element?.linkedin && (
                                                                    <li>
                                                                        <a href={element.linkedin}>
                                                                            <i className="fab fa-linkedin" aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="list-more-btn text-center">
                                        <Link to="/volunteers" className="button-round">
                                            View All Member
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    <section className="home-about home-about-founder py-5" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                        <div className="container">
                            <div className="row">
                                <div className="sub-title">Welcome to our Service Directory, dedicated space created</div>
                                <div className="col-lg-5">
                                    <div className="home-about-right">
                                        <div className="home-about-content">
                                            <h2 className="section-title text-dark" style={{ fontSize: 43 }}>
                                                By the Community and for the Community.
                                            </h2>
                                            <p className="para_light">
                                                Our mission is to foster a supportive and connected environment for the residents of our Beautiful Nangal and the surrounding 100+ villages. We believe in the power of collective effort and aim to make a positive impact on the lives of our community members through various initiatives.
                                            </p>
                                            <div className="mb-3">
                                                <h3>Our Initiatives:</h3>
                                                <Accordion className="w-100">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <h3 style={{ fontSize: 15, marginBottom: 0 }}>Volunteer Participation</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>
                                                                We encourage community members to get involved in local projects and events. Volunteering is a great way to give back, meet new people, and make a difference. Together, we can tackle local challenges and create a stronger, more vibrant community.
                                                            </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>
                                                            <h3 style={{ fontSize: 15, marginBottom: 0 }}>Blood Donation</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>
                                                                Blood donation is a critical need that can save lives. We organize regular blood drives and provide information on where and how you can donate. Join us in this lifesaving effort and become a hero to those in need.                                                            </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>
                                                            <h3 style={{ fontSize: 15, marginBottom: 0 }}>Health Awareness</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>
                                                                Good health is the foundation of a happy life. Our health awareness programs aim to educate and inform community members about healthy living, preventive care, and managing common health issues.                                                                                                     </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>
                                                            <h3 style={{ fontSize: 15, marginBottom: 0 }}>Promoting Active Sports</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>
                                                                Physical activity is essential for overall well-being. We promote active sports and recreational activities to encourage a healthy lifestyle. From local sports events to regular fitness sessions, there's something for everyone to get moving and stay fit.                                                                                                                                                      </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>
                                                            <h3 style={{ fontSize: 15, marginBottom: 0 }}>Self-Development and Financial Freedom</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>
                                                                Personal growth and financial stability are key to a fulfilling life. Our blogs and workshops cover a range of topics from self-improvement strategies to financial planning and investment tips. We aim to empower community members with the knowledge and skills to achieve their personal and financial goals.                                                            </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="home-about-left">
                                        <div className="home-about-image">
                                            <img src={nbcArt} alt="unboundImg3" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-md-flex gap-5">
                                    <div>
                                        <h3>Get Involved:</h3>
                                        <p>This platform is designed for active participation. We urge you to stay engaged, share your interests, and support one another in every possible way. By connecting and collaborating, we can help each other grow and build a brighter future for our community.</p>
                                    </div>
                                    <div>
                                        <h3>Join Us:</h3>
                                        <p>This is a small step towards a better tomorrow where we work together as a team. Explore our Service Directory, find opportunities that interest you, and join hands with us to make a difference. Together, we can create a thriving, supportive community where everyone can flourish.                                         Let’s connect, contribute, and create a better tomorrow for all.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="home-testimonial position-relative"
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-center"
                        data-aos-duration="1000"
                        style={{ position: 'relative', overflow: 'hidden' }} // Ensure the section allows for overflow
                    >
                        {/* Carousel as Background */}
                        <Carousel indicators={false} controls={false} slide={true} interval={4000} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
                            {galleryImages?.slice(0, 5).map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        src={image?.image_url} // Assuming each image object has an image_url property
                                        alt={`Gallery Image ${index + 1}`}
                                        style={{
                                            width: '100%', // Use 100% to make it responsive
                                            height: '930px', // Set your desired height
                                            objectFit: 'cover', // Ensures the image covers the area without distortion
                                        }}
                                        className="d-block" // Keep Bootstrap's d-block class for display
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>

                        <div
                            className="container"
                        >
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="testimonial-inner testimonial-slider">
                                        <HomeTestimonialSlider />
                                    </div>
                                </div>
                                <div className="col-lg-6 our_testimonial">
                                    <div className="sub-title">OUR TESTIMONIALS</div>
                                    <h2 className="section-title">
                                        What our happy donors say !!
                                    </h2>
                                    <p>
                                        Nangal By Cycle goes above and beyond, attacking the root causes of core issues our society faces. I'm honored to support an organization that thinks progressively and isn't afraid to take on big challenges.
                                    </p>
                                    <Link to="/contact" className="outline-round outline-round-white">
                                        Contact us
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="overlay"
                        ></div>
                    </section>
                    <section className="founder">
                        <div className="container">
                            <div className="founder-inner">
                                <div className="row">
                                    <div className="col-lg-5" data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-duration="3000">
                                        <article
                                            className="event-item event-item-bg founder_box"
                                            style={{ background: "#364F75" }}
                                        >
                                            <div className="founder-content">
                                                <figure className="founder-img circle">
                                                    <img src={founderImg} alt="Founder" />
                                                    <svg
                                                        viewBox="0 0 100 100"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle cx="50" cy="50" r="40" />
                                                    </svg>
                                                </figure>
                                                <div className="text-content text-center">
                                                    <div className="name-title">
                                                        <h3 className="mb-0">
                                                            <Link to="#"> Rohit Garg</Link>
                                                        </h3>
                                                        <h5 className="mt-2 mb-3">
                                                            <Link to="#">- Founder</Link>
                                                        </h5>
                                                    </div>
                                                    <p>
                                                        Rohit Garg, born in the tranquil village of Bhabour
                                                        Sahib during the tumultuous era of Emergency rule in
                                                        India in August 1975, is the visionary founder of
                                                        our community support website. Raised in the embrace
                                                        of history and nature, Rohit's humble beginnings
                                                        instilled in him a profound sense of gratitude and a
                                                        commitment to giving back to society.
                                                    </p>
                                                </div>
                                                <div className="socialgroup text-center">
                                                    <ul className="p-0">
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.facebook.com/"
                                                                rel="noreferrer"
                                                            >
                                                                <i className="fab fa-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.google.com/"
                                                                rel="noreferrer"
                                                            >
                                                                <i className="fab fa-google"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.twitter.com/"
                                                                rel="noreferrer"
                                                            >
                                                                <i className="fab fa-x-twitter"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.instagram.com/"
                                                                rel="noreferrer"
                                                            >
                                                                <i className="fab fa-instagram"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.linkedin.com/in/rohit-garg-0b342318/"
                                                                rel="noreferrer"
                                                            >
                                                                <i className="fab fa-linkedin"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="overlay"></div>
                                        </article>
                                    </div>
                                    <div className="col-lg-7">
                                        <article className="event-item p-0">
                                            <Accordion defaultActiveKey="0" className="w-100">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h3>Roots in Humility & Kindness</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Growing up in the scenic surroundings of Bhabour
                                                            Sahib, Rohit's childhood was enriched by the
                                                            presence of his grandmother (Naani -Smt.Kamla
                                                            Devi), revered figure known for her kindness and
                                                            strength in the village. Her teachings of humility
                                                            and compassion became the guiding principles of
                                                            Rohit's life, shaping his character and inspiring
                                                            his dedication to community service.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h3>Passion for Sports & Eco-Friendly Living</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            In his youth, Rohit's love for cricket mirrored
                                                            that of many children of his era. Today, his
                                                            passion for sports endures, albeit in the form of
                                                            avidly following cricket matches and embracing the
                                                            eco-friendly pursuit of cycling. Through his
                                                            commitment to sustainable living, Rohit seeks to
                                                            leave a positive impact on the environment and
                                                            inspire others to adopt similar practices.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h3>Life Lessons from Global Experiences</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Rohit's professional journey has taken him across
                                                            continents, from Chandigarh to Dubai and Lebanon,
                                                            where he weathered the storms of political unrest,
                                                            including the harrowing experience of the July
                                                            2006 War between Israel and Lebanon. These
                                                            experiences not only enriched his professional
                                                            acumen but also imparted invaluable life lessons
                                                            in crisis management and resilience.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h3>Leadership in Finance & Business</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            As seasoned financial professional, Rohit
                                                            currently serves as the Chief Financial Officer
                                                            for the Al Habtoor Group, a prestigious
                                                            multinational conglomerate based in the United
                                                            Arab Emirates. In this role, he oversees the
                                                            financial operations of the company across
                                                            multiple locations, playing a pivotal role in
                                                            shaping its financial strategy and ensuring its
                                                            continued success.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h3>A Legacy of Leadership & Integrity</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Throughout his career, Rohit has been lauded for
                                                            his exemplary leadership and unwavering commitment
                                                            to financial stability. His integrity, coupled
                                                            with his astute financial acumen, has earned him
                                                            the respect and admiration of colleagues, industry
                                                            peers, and partners alike. Through his steadfast
                                                            dedication to community service and his tireless
                                                            efforts to make a positive difference in the
                                                            world, Rohit Garg embodies the spirit of
                                                            compassion, resilience, and leadership, inspiring
                                                            us all to strive for a better tomorrow.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="founder-inner">
                                <div className="row accordian_order">
                                    <div className="col-lg-7">
                                        <article className="event-item p-0">
                                            <Accordion defaultActiveKey="0" className="w-100">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h3>Passionate Learner & Coder</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            With keen interest in technology, Lakshya has
                                                            delved into the world of coding over the past
                                                            three years. His curiosity and dedication have led
                                                            him to become an integral part of our development
                                                            team, where he lends his skills and enthusiasm to
                                                            create meaningful tools for our community.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h3>Basketball Enthusiast</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Beyond his love for coding, Lakshya finds joy in
                                                            playing games with friends, with NBA basketball
                                                            holding special place in his heart. He hones his
                                                            skills at the local Basketball Academy, where he
                                                            learns the value of teamwork and discipline.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h3>Environmental Advocate</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Driven by desire to contribute to a cleaner and
                                                            quieter environment, Lakshya is passionate about
                                                            reducing pollution, including the unnecessary
                                                            noise pollution caused by incessant honking on
                                                            Indian roads. He believes in taking proactive
                                                            steps to preserve Mother Nature for future
                                                            generations.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h3>Gourmet Explorer</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            In his downtime, Lakshya indulges in his favorite
                                                            foods, which include pizza and Manakeesh (Lebanese
                                                            pizza), along with a side of hummus. His culinary
                                                            adventures reflect his adventurous spirit and
                                                            appreciation for diverse cultures.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h3>Academic Journey</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Currently Year IB 6 student, Lakshya eagerly
                                                            anticipates the start of high school in September
                                                            2024, where he plans to continue his academic
                                                            pursuits alongside his commitment to making a
                                                            difference in the community. <br /> Through his
                                                            dedication, creativity, and unwavering passion,
                                                            Lakshya inspires us all to strive for excellence
                                                            and to create a brighter future for generations to
                                                            come.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </article>
                                    </div>
                                    <div className="col-lg-5" data-aos="fade-left" data-aos-anchor-placement="top-center" data-aos-duration="3000">
                                        <article
                                            className="event-item event-item-bg founder_box"
                                            style={{ background: "#364F75" }}
                                        >
                                            <div className="founder-content">
                                                <figure className="founder-img circle">
                                                    <img
                                                        src={LakshyaImg}
                                                        alt="Lakshya"
                                                    />
                                                    <svg
                                                        viewBox="0 0 100 100"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle cx="50" cy="50" r="40"></circle>
                                                    </svg>
                                                </figure>
                                                <div className="text-content text-center">
                                                    <div className="name-title">
                                                        <h3 className="mb-0">
                                                            <Link to="#"> Lakshya Garg</Link>
                                                        </h3>
                                                        <h5 className="mt-2 mb-3">
                                                            <Link to="#">- Co-Founder & Junior Developer</Link>
                                                        </h5>
                                                    </div>
                                                    <p>
                                                        Lakshya Garg, born in the vibrant city of Beirut in
                                                        29th January 2013, is young and dynamic force behind
                                                        our community support website. At just 11 years old,
                                                        Lakshya embodies zest for life and a determination
                                                        to make a positive impact in the world around him.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="overlay"></div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="founder-inner">
                                <div className="row">
                                    <div className="col-lg-5" data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-duration="3000">
                                        <article
                                            className="event-item event-item-bg founder_box"
                                            style={{ background: "#364F75" }}
                                        >
                                            <div className="founder-content">
                                                <figure className="founder-img circle">
                                                    <img src={lavanyaGargImage} alt="" />
                                                    <svg
                                                        viewBox="0 0 100 100"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle cx="50" cy="50" r="40" />
                                                    </svg>
                                                </figure>
                                                <div className="text-content text-center">
                                                    <div className="name-title">
                                                        <h3 className="mb-0">
                                                            <Link to="#"> Lavanya Garg</Link>
                                                        </h3>
                                                        <h5 className="mt-2 mb-3">
                                                            <Link to="#">- Co-founder & Creative Designer</Link>
                                                        </h5>
                                                    </div>
                                                    <p>
                                                        Lavanya Garg, budding teenager who turned thirteen
                                                        in September 2023, is vital force behind our
                                                        community support website. Born in the historic city
                                                        of Byblos, Lebanon, Lavanya brings blend of
                                                        creativity and passion to our team, enriching our
                                                        endeavors with her unique perspective.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="overlay"></div>
                                        </article>
                                    </div>
                                    <div className="col-lg-7">
                                        <article className="event-item p-0">
                                            <Accordion defaultActiveKey="0" className="w-100">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h3>Passionate Artist & Crafter</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            From young age, Lavanya has been captivated by the
                                                            world of art and craft. Her self-driven learning
                                                            and exploration have fostered deep appreciation
                                                            for creativity, which she channels into every
                                                            aspect of her life. As our resident creative
                                                            designer, Lavanya infuses our website with her
                                                            artistic flair, shaping its identity and enhancing
                                                            its visual appeal.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h3>Social Butterfly & Volleyball Enthusiast</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            With natural inclination towards social
                                                            interaction, Lavanya thrives on meaningful
                                                            conversations and connections. Alongside her
                                                            friends, she finds joy in engaging discussions and
                                                            shared experiences. Volleyball holds a special
                                                            place in Lavanya's heart, and she dedicates her
                                                            time to honing her skills at the local academy,
                                                            where she learns valuable lessons in teamwork and
                                                            perseverance.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h3>Environmental Advocate</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Driven by a deep-seated commitment to
                                                            environmental conservation, Lavanya advocates for
                                                            paperless future and sustainable transportation
                                                            options. She believes in the importance of
                                                            preserving nature for future generations and
                                                            actively promotes initiatives such as reducing
                                                            paper usage and encouraging the use of public
                                                            transport or bicycles.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h3>Academic Pursuits & Love for Music</h3>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>
                                                            Currently an enthusiastic Year IB 8 student,
                                                            Lavanya embraces the opportunity to learn and grow
                                                            each day. Her insatiable curiosity fuels her
                                                            passion for education, as she eagerly explores new
                                                            topics and ideas. In her free time, Lavanya finds
                                                            solace in music, which serves as a source of
                                                            inspiration and relaxation amidst her busy
                                                            schedules. <br />
                                                            Through her artistic vision, dedication to
                                                            environmental stewardship, and unwavering
                                                            commitment to community support, Lavanya embodies
                                                            the spirit of innovation and compassion, inspiring
                                                            us all to strive for a brighter, more sustainable
                                                            future.
                                                        </p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {galleryImages && galleryImages.length > 0 && (
                        <section className="home-gallery bg_dark_2">
                            <div className="container">
                                <div className="section-head text-center">
                                    <div className="sub-title">PORTFOLIO / GALLERY</div>
                                    <h2 className="section-title">Our photo gallery</h2>
                                </div>
                                <div className="gallery-inner">
                                    <div className="gallery-container grid">
                                        <Fancybox
                                            options={{
                                                Carousel: {
                                                    infinite: false,
                                                },
                                            }}
                                        >
                                            {galleryImages.map((image, index) => (
                                                <div
                                                    className="single-gallery grid-item"
                                                    data-aos="flip-up"
                                                    data-aos-anchor-placement="top-center"
                                                    data-aos-duration={index % 2 === 0 ? "1000" : "2000"}
                                                    key={image?.image_id}
                                                >
                                                    <figure className="gallery-img">
                                                        <Link
                                                            target="_blank"
                                                            to={image.image_url}
                                                            data-fancybox="gallery"
                                                        >
                                                            <img
                                                                src={image.image_url}
                                                                alt={image?.title}
                                                            />
                                                        </Link>
                                                    </figure>
                                                </div>
                                            ))}
                                        </Fancybox>
                                    </div>
                                    <div className="list-more-btn text-center">
                                        <Link to="/gallery" className="button-round">
                                            View Gallery
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    {blogsList && blogsList.length > 0 && (
                        <section className="home-blog" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                            <div className="container">
                                <div className="section-head d-sm-flex align-items-center justify-content-between">
                                    <div className="head-wrap">
                                        <div className="sub-title">OUR LATEST BLOG</div>
                                        <h2 className="section-title">Checkout news & updates</h2>
                                    </div>
                                    <div className="head-btn d-md-block d-none">
                                        <Link to="/blogs" className="button-round">
                                            View All Blog
                                        </Link>
                                    </div>
                                </div>
                                <div className="blog-inner">
                                    <div className="row">
                                        {blogsList.map((element) => (
                                            <div className="col-md-6 col-lg-4" key={element.id}>
                                                <article className="post" style={{ backgroundColor: '#343434' }}>
                                                    {element.thumbnail != null && (
                                                        <figure
                                                            className="feature-image"
                                                            style={{
                                                                maxWidth: "500px",
                                                                maxHeight: "233px",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URL}/public/uploads/blogThumbnail/${element?.thumbnail}`}
                                                                    alt="unboundImg22"
                                                                />
                                                            </Link>
                                                        </figure>
                                                    )}
                                                    <div className="entry-content">
                                                        <h3>
                                                            <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>{truncateText(element?.title, 36)}</Link>
                                                        </h3>
                                                        <div className="entry-meta">
                                                            <span className="byline">
                                                                <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                                                    <FaUser className="me-2" style={{ color: '#F15B44' }} />
                                                                    {element?.author_name}
                                                                </Link>
                                                            </span>
                                                            <span className="posted-on">
                                                                <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                                                    <BsCalendar2DateFill className="me-2" style={{ color: '#F15B44' }} />
                                                                    {formatDateblog(element?.publish_date)}
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="head-btn d-block d-md-none">
                                    <Link to="/blogs" className="button-round">
                                        View All Blog
                                    </Link>
                                </div>
                            </div>
                        </section>
                    )}
                    <section
                        className="home-callback"
                        style={{ backgroundImage: `url(${unboundImg2})` }}
                    >
                        <div className={`container`}>
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-8">
                                    <div className="callback-content">
                                        <h2 className="section-title">Join our Local Blogs!</h2>
                                        <p>
                                            Dive into the heart of our community through insightful
                                            local blogs. Explore stories, experiences, and
                                            perspectives shared by residents, covering everything from
                                            cultural events to neighborhood initiatives. Join the
                                            conversation and contribute your own stories to enrich our
                                            collective narrative.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="button-wrap">
                                        <Link to="/volunteers" className="button-round">
                                            LEARN MORE
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overlay"></div>
                    </section>
                </main>
            )}
        </>
    );
}

export default Home;