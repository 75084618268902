import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { approveEvent, deleteEvent, getAllRequestEvents, getEvent } from "../../../Slice/events";
import Loader from "../../Loader";
import { confirmAlert } from "react-confirm-alert";
import EventModelView from "./EventModelView";
import { Table } from "antd";
import { GiCheckMark } from "react-icons/gi";

const RequestList = () => {
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventViewModalOpen, setEventViewModalOpen] = useState(false);
  const toggleEventViewModal = () => setEventViewModalOpen(!isEventViewModalOpen);
  const { totalCount, eventRequests, isLoading } = useSelector((state) => state.event);
  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 5,
  });

  useEffect(() => {
    dispatch(getAllRequestEvents(state.search, state.page, state.pagesize));
  }, [dispatch, state.search, state.page, state.pagesize]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleApproveClick = (id) => {
    confirmAlert({
      title: "Confirm to approve",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleApprove(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const navigate = useNavigate();

  const handleUpdateClick = (id) => {
    dispatch(getEvent(id));
    navigate(`/admin/update-event/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteEvent(id));
  };

  const handleApprove = (id) => {
    dispatch(approveEvent(id));
  };

  const handleViewClick = (event) => {
    setSelectedEvent(event);
    toggleEventViewModal(event);
  };

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, page: 1, pagesize: pageSize });
  };

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button className="btn btn-sm btn-primary">Previous</button>;
    }
    if (type === 'next') {
      return <button className="btn btn-sm btn-primary">Next</button>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        index + 1
      ),
    },
    {
      title: 'Event Title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: 'Organiser Name',
      dataIndex: 'organiser',
      sorter: (a, b) => a.organiser.length - b.organiser.length,
    },
    {
      title: 'Phone Number',
      dataIndex: 'contact',
      render: (text) => (
        text ? text : 'XXXXXXXXXX'
      ),
      sorter: (a, b) => (a.contact || '').length - (b.contact || '').length,
    },
    {
      title: 'Start Date/Time',
      dataIndex: 'startDateTime',
      render: (text, record, index) => (
        formatDate(text)
      )
    },
    {
      title: 'End Date/Time',
      dataIndex: 'endDateTime',
      render: (text, record, index) => (
        formatDate(text)
      )
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: (a, b) => a.location.length - b.location.length,
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
          <Link to="#"
            className="dropdown-item px-2 text-warning" onClick={() => {
              handleApproveClick(record.eventId)
            }}>
            <GiCheckMark />
          </Link>
          <Link to={`/admin/update-event/${record.eventId}`}
            className="dropdown-item px-2 text-warning" onClick={() => {
              handleUpdateClick(record.eventId)
            }}>
            <i className="fa fa-pencil m-r-5" />
          </Link>
          <Link
            to={`#`}
            title="View Profile"
            className="dropdown-item px-2 text-primary"
            onClick={() => handleViewClick(record)}
          >
            <i className={`fa fa-user`}></i>
          </Link>
          <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
            handleDeleteClick(record.eventId);
          }}>
            <i className="fa fa-trash m-r-5" />
          </Link>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="container-fluid mt-2">
        <div className='row justify-content-between align-items-center all_donor_header mb-2'>
          <div className='col-auto'>
            <h1 className='h2'>Un-Approved Event List</h1>
          </div>
          <div className="col-auto">
            <Link to={"/admin/add-event"} className={`button-round border_radius`} type="button">
              <i className={`fa fa-plus`} aria-hidden="true"></i> Add Event
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className="col-md-3 col-lg-3 col-sm-3 mb-2 mb-lg-2">
            <input type="text" className="form-control" placeholder="Search" value={state.search} onChange={(e) => setState({ ...state, search: e.target.value })} />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              pagination={{
                current: state.page,
                pageSize: state.pagesize,
                total: totalCount,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
                onChange: (page, pageSize) =>
                  setState({ ...state, page, pagesize: pageSize }),
              }}
              bordered
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={eventRequests}
              rowKey={(record) => record.eventId}
            />
          )}
        </div>
        <EventModelView
          isEventViewModalOpen={isEventViewModalOpen}
          toggleEventViewModal={toggleEventViewModal}
          selectedEvent={selectedEvent}
        />
      </div >
    </>
  )
}

export default RequestList