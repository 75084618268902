import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import innerBanner from "../../Assets/Images/Event-bg-01-01.jpg";
import { getAllDonors } from "../../Slice/bloodDonation";
import { Link } from "react-router-dom";
import genericImg from "../../Assets/Images/Generic-img.png";
import { BloodGroupOptions } from "../../constants/index";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";
import { numberToString } from "../../utils/encrypt";
import { rewriteUrl } from "../../Components/constant/Constant";

const Donors = () => {
  const dispatch = useDispatch();
  const { donors, isLoading, totalCount } = useSelector((state) => state.donor);
  const [state, setState] = useState({
    search: "",
    selectedBloodGroup: "",
    pageSize: 6,
    page: 1,
  });


  useEffect(() => {
    dispatch(getAllDonors(state.search, state.page, state.pageSize, state.selectedBloodGroup));
  }, [dispatch, state.page, state.pageSize]);

  const optionsBloodGroup = BloodGroupOptions?.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const handleSearch = () => {
    setState({ ...state, page: 1 });
    dispatch(getAllDonors(state.search, state.page, state.pageSize, state.selectedBloodGroup));
  };

  const handleViewMore = () => {
    setState((prevState) => ({
      ...prevState,
      pageSize: prevState.pageSize + state.pageSize,
    }));
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main bg-light-grey">
          <Helmet>
            <title>Support Nangal By Cycle | Donate to Promote Sustainable Tourism</title>
            <meta name="description" content="Become a donor for Nangal By Cycle and help us promote eco-friendly tourism, maintain trails, and support community cycling initiatives in Nangal." />
          </Helmet>
          <section className="inner-banner-wrap pb-5">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBanner})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Our Donors </h1>
                </div>
              </div>
            </div>
          </section>
          <form className="container main-contact-info-item">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="form-group mb-2">
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    value={state.selectedBloodGroup}
                    onChange={(e) =>
                      setState({
                        ...state,
                        selectedBloodGroup: e.target.value,
                      })
                    }
                  >
                    {optionsBloodGroup?.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        disabled={option.isDisabled}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control bg-white"
                    value={state.search}
                    onChange={(e) =>
                      setState({ ...state, search: e.target.value })
                    }
                    placeholder="Find Location"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="form-group mb-2">
                  <button
                    type="button"
                    className="btn btn-primary w-100 form-control btn-lg btn-block"
                    style={{ background: "#f15b44" }}
                    onClick={handleSearch}
                  >
                    Search Donor
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="team-page-section">
            <div className="container-fluid p-5">
              <div className="row">
                {donors.length > 0 ? (
                  donors?.map((element) => (
                    <div className="col-lg-6" key={element.donorId}>
                      <article className="team-item">
                        <figure className="team-img">
                          <img
                            src={
                              element.userProfile
                                ? element.userProfile
                                : genericImg
                            }
                            alt="/"
                          />
                        </figure>
                        <div className="team-content">
                          <h3>{element.name}</h3>
                          <span>Blood Group : {element.bloodType}</span>
                          {/* <p>
                            <i className="fas fa-mobile" style={{ color: '#CB4B36' }}>
                            </i> : {element.mobile}
                          </p> */}
                          <span>
                            <div className="d-flex">
                              <i className="me-1 fas fa-envelope" style={{ color: '#CB4B36' }}></i>
                              <Link to="#" className="text-break">
                                {element?.email}
                              </Link>
                            </div>
                          </span>
                          <p><i className="me-1 fas fa-map-marker-alt" style={{ color: '#CB4B36' }}></i>{element.village}</p>
                          <div className="social-links">
                            <Link
                              to={`/donor/${rewriteUrl(element.name)}-${numberToString(element.donorId)}`}
                              className="button-round px-4 py-3 text-white"
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h3>No items found</h3>
                  </div>
                )}
                {((state.page * state.pageSize) < totalCount) && (
                  <div div className="text-center" >
                    <button onClick={handleViewMore} className="button-round">View More</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main >
      )}
    </>
  );
};

export default Donors;
