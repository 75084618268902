import React, { useEffect } from 'react';
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuser } from '../../Slice/volunteers';
import genericImg from "../../Assets/Images/Generic-img.png";
import Loader from "../../Components/Loader";
import { stringToNumber } from "../../utils/encrypt";
import moment from 'moment';
import { Helmet } from 'react-helmet';

function VolunteerDetail() {
    const { id } = useParams();
    const lastIndex = id.lastIndexOf('-');
    const donorId = id.substring(lastIndex + 1);
    const intID = stringToNumber(donorId);
    const dispatch = useDispatch();
    const { user, isLoading } = useSelector((state) => state.user);

    useEffect(() => {
        debugger
        dispatch(getuser(intID));
    }, [dispatch, intID]);

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    // const handleGetInTouch = () => {

    //     const ccEmail = "info@nangalbycycle.com";
    //     if (user?.contactMode === "email") {
    //         window.location.href = `mailto:${user?.email}`;
    //     } else if (user?.contactMode === "mobile") {
    //         window.location.href = `tel:${user?.mobile}`;
    //     }
    // };

    const handleGetInTouch = () => {
        const userEmail = user?.email;
        const ccEmail = "info@nangalbycycle.com";
        const mailtoLink = `mailto:${userEmail}?cc=${ccEmail}`;
        window.location.href = mailtoLink;
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <main id="content" className="site-main">
                    <Helmet>
                        <title>Volunteer for a Greener Tomorrow</title>
                        <meta name="description" content="Join Nangal By Cycle as a volunteer and help promote sustainable transportation. Contribute to community well-being, reduce carbon footprint, and enjoy scenic cycling routes. Make a difference today!" />
                    </Helmet>
                    <section className="inner-banner-wrap">
                        <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImg1})` }}>
                            <div className="container">
                                <div className="inner-banner-content">
                                    <h1 className="inner-title">Volunteer</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    {user ? (
                        <>
                            <section className="py-3 py-md-5 py-xl-8">
                                <div className="container">
                                    <div className="row gy-4 gy-lg-0">
                                        <div className="col-12 col-lg-4 col-xl-3">
                                            <div className="row gy-4">
                                                <div className="col-12">
                                                    <div className="card widget-card border-light shadow-sm">
                                                        <div className="card-body">
                                                            <div className="text-center mb-3">
                                                                <img
                                                                    src={user?.userProfile || genericImg}
                                                                    alt="Profile"
                                                                    className="rounded-circle img-fluid img-thumbnail avatar-img"
                                                                />
                                                            </div>
                                                            <h3 className="text-center mb-1">{user?.name}</h3>
                                                            <p className="text-center text-secondary mb-4">{user?.profession}</p>
                                                            <div className="d-grid m-0">
                                                                <button className="btn btn-outline-primary button-round" type="button" onClick={handleGetInTouch}>Get In Touch</button>
                                                            </div>
                                                            <div className="socialgroup text-center">
                                                                <ul className="p-0">
                                                                    {user?.socialMedia && Object.entries(JSON.parse(user.socialMedia)).map(([platform, url]) => (
                                                                        url && (
                                                                            <li key={platform}>
                                                                                <a
                                                                                    target="_blank"
                                                                                    href={url}
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <i className={`fab fa-${platform.toLowerCase()}`}></i>
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8 col-xl-9">
                                            <div className="card widget-card border-light shadow-sm">
                                                <div className="card-body p-4">
                                                    <ul className="nav nav-tabs" id="profileTab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Detail</button>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content pt-4" id="profileTabContent">
                                                        <div className="tab-pane fade active show" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                                            {user?.about && (
                                                                <>
                                                                    <h4 className="mb-3">About</h4>
                                                                    <p className="mb-3">{user.about}</p>
                                                                </>
                                                            )}
                                                            <h5 className="mb-3">Profile</h5>
                                                            <div className="row g-0">
                                                                <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                                                                    <div className="p-2">Full Name</div>
                                                                </div>
                                                                <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                                                                    <div className="p-2">{user?.name}</div>
                                                                </div>
                                                                <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                                                                    <div className="p-2">Email</div>
                                                                </div>
                                                                <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                                                                    <div className="p-2">{user?.email}</div>
                                                                </div>
                                                                <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                                                                    <div className="p-2">Village/City</div>
                                                                </div>
                                                                <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                                                                    <div className="p-2">{user?.village}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    ) : (
                        <div>Loading donors...</div>
                    )}
                </main>
            )}
        </>
    );
};

export default VolunteerDetail;       