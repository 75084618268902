import React from 'react'
import Slider from 'react-slick';
import logo1 from '../../Assets/Images/Logo-slider-1.png';
import logo2 from '../../Assets/Images/Logo-slider-2.png';
import logo3 from '../../Assets/Images/Logo-slider-3.png';
import logo4 from '../../Assets/Images/Logo-slider-4.png';
import logo5 from '../../Assets/Images/Logo-slider-5.png';
import logo6 from '../../Assets/Images/Logo-slider-6.png';
import logo7 from '../../Assets/Images/Logo-slider-7.png';
import logo8 from '../../Assets/Images/Logo-slider-8.png';
import logo9 from '../../Assets/Images/Logo-slider-9.png';
import logo10 from '../../Assets/Images/Logo-slider-10.png';

const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 3,
    autoplay: true,
    responsive: [
        { breakpoint: 768, settings: { slidesToShow: 2 } },
        { breakpoint: 479, settings: { slidesToShow: 2 } },
    ],
};

function ClientSlider() {
    return (
        <>
            <Slider {...settings}>
                <div className="client-item">
                    <figure>
                        <img src={logo1} alt="logo1" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo2} alt="logo2" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo3} alt="logo1" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo4} alt="logo2" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo5} alt="logo1" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo6} alt="logo2" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo7} alt="logo1" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo8} alt="logo2" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo9} alt="logo1" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo10} alt="logo2" />
                    </figure>
                </div>
            </Slider>

            <style jsx>{`
                .client-item {
                    padding: 0 30px; /* This will add a gap between the slides */
                }
                
                .slick-list {
                    margin: 0 -10px; /* Adjusts the overall alignment */
                }
            `}</style>
        </>
    )
}

export default ClientSlider