import React, { useEffect, useState } from "react";
import unboundLogo from "../Assets/Images/logo7.png";
import unboundLogo1 from "../Assets/Images/unbound-logo1.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import TranslateComponent from "./TranslateComponent";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logoutUser } from "../Slice/authLogin";
import genericImg from '../../../clientapp/src/Assets/Images/Generic-img.png';
import { ROLES } from "./constant/Constant";
import { MdClose } from "react-icons/md";

function Header({ mobiletoggleNav, toggleNav, isNavVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [showCommunity, setShowCommunity] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [showDiscover, setShowDiscover] = useState(false);

  const toggleCommunity = () => setShowCommunity(!showCommunity);
  const toggleMedia = () => setShowMedia(!showMedia);
  const toggleDiscover = () => setShowDiscover(!showDiscover);
  const handleLogout = () => dispatch(logoutUser(navigate));

  const updateProfileFromStorage = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
  };

  useEffect(() => {
    updateProfileFromStorage();
    window.addEventListener('storage', updateProfileFromStorage);
    return () => window.removeEventListener('storage', updateProfileFromStorage);
  }, []);


  return (
    <>
      <header id="masthead" className="site-header site-header-transparent">
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 d-none d-lg-block">
                <div className="header-contact-info">
                  <ul className="d-none">
                    <li>
                      <a href="tel:+01-977-2599-12">
                        <i className="fas fa-phone-alt"></i> +01 (977) 2599 12
                      </a>
                    </li>
                    <li>
                      <a href="https://demo.bosathemes.com/cdn-cgi/l/email-protection#6605090b1607081f2602090b070f084805090b">
                        <i className="fas fa-envelope"></i>{" "}
                        <span
                          data-cfemail="99faf6f4e9f8f7e0d9fdf6f4f8f0f7b7faf6f4"
                        >
                          [email&#160;protected]
                        </span>
                      </a>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i> 3146 Koontz
                      Lane, California
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between">
                <div className="header-social social-links">
                  <ul>
                    <li>
                      <Link to="https://www.facebook.com/nangalbycycle" target="_blank">
                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://x.com/nangalbycycle" target="_blank">
                        <i className="fab fa-x-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/@Nangalbycycle" target="_blank">
                        <i className="fab fa-youtube" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/nangalbycycle" target="_blank">
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TranslateComponent />
        <div className="bottom-header">
          <div className="container d-flex justify-content-between align-items-center">
            <div className="site-identity">
              <h1 className="site-title">
                <Link to="/home">
                  <img className="white-logo" src={unboundLogo} alt="logo" />
                  <img className="black-logo" src={unboundLogo1} alt="logo1" />
                </Link>
              </h1>
            </div>
            {isNavVisible ?
              <Link onClick={toggleNav} to="#" aria-haspopup="true" role="button" tabIndex="0" className={`${isNavVisible ? 'd-lg-none' : 'd-block'} slicknav_btn slicknav_collapsed`} style={{ outline: "none" }}>
                <MdClose style={{ color: 'white', fontSize: 23 }} />
              </Link>
              :
              <Link onClick={toggleNav} to="#" aria-haspopup="true" role="button" tabIndex="0" className={`slicknav_btn slicknav_collapsed ${isNavVisible ? 'd-block' : 'd-lg-none'}`} style={{ outline: "none" }}>
                <span className="slicknav_icon">
                  <span className="slicknav_icon-bar"></span>
                  <span className="slicknav_icon-bar"></span>
                  <span className="slicknav_icon-bar"></span>
                </span>
              </Link>
            }
            <div className="main-navigation d-lg-flex d-none align-items-center">
              <nav id="navigation" className="navigation d-none d-lg-inline-block">
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "current-menu-item" : ""}`
                      }
                      to="/home"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="menu-item-has-children">
                    <NavLink to="#">Community Members</NavLink>
                    <ul>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to="/volunteers"
                        >
                          Volunteers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to="/donors"
                        >
                          Blood Donors
                        </NavLink>
                      </li>
                      <li><NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/skilled-persons">NBC Members</NavLink></li>
                      <li><NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/nangal-heroes">Nangal Heroes</NavLink></li>
                      <li><NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/become-volunteer">Become a Volunteer</NavLink></li>
                      <li><NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/become-donor">Become a Blood Donor</NavLink></li>
                      <li><NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/become-nbc-member">Become an NBC Member</NavLink></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <NavLink to="#">Media & Events</NavLink>
                    <ul>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to="/events"
                        >
                          Events
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) => (isActive ? "current-menu-item" : "")}
                          to="/blogs"
                        >
                          Blogs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) => (isActive ? "current-menu-item" : "")}
                          to="/Newsarchive"
                        >
                          news
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to="/gallery"
                        >
                          Gallery
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <NavLink to="#">Discover Us</NavLink>
                    <ul>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to={"/about"}>
                          <span className="semi-bold">Who We Are</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to={"/team"}>
                          <span className="semi-bold">Team</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to={"/specializedskills"}>
                          <span className="semi-bold">Services</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {
                    !user ? (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "current-menu-item" : ""
                          }
                          to="/auth/login"
                        >
                          Sign in
                        </NavLink>
                      </li>
                    ) : ("")
                  }
                </ul>
              </nav>
              <div className="d-flex gap-3 flex-wrap">
                <div className="header-btn d-lg-block d-none">
                  <NavLink to="/contact" className="button-round">
                    Contact us
                  </NavLink>
                </div>
                {
                  user &&
                  <Dropdown >
                    <Dropdown.Toggle id="dropdown-basic" className='btn-dark'>
                      <img src={user?.userProfile ? user?.userProfile : genericImg} alt="user" width="40" height="40" className="user_profile_image" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item><Link to={user.roleName.includes(ROLES.Admin) ? "/admin/user-profile" : "/user/edit-profile"}>
                        <i className="fa-solid fa-user px-2"></i>
                        Edit Profile
                      </Link>
                      </Dropdown.Item>
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/socialMedia-links">
                          <i className="fa-solid fa-users px-2"></i>
                          Social Media</Link>
                        </Dropdown.Item>
                      }
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/blogs">
                          <i className="fa-solid  fa-pen px-2 pt-1"></i>
                          Blogs</Link>

                        </Dropdown.Item>
                      }
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/events">
                          <i className="fa-solid fa-list px-2 pt-1"></i>
                          Events</Link>
                        </Dropdown.Item>
                      }
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/gallery">
                          <i className="fa-solid fa-image px-2 pt-1"></i>
                          Gallery</Link>
                        </Dropdown.Item>
                      }
                      <Dropdown.Item><Link to="/password/change-password">
                        <i className="fa-solid fa-key px-2"></i>
                        Change Password</Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout} style={{ color: 'red' }}>
                        <i className="fa-solid fa-arrow-right-from-bracket px-2"></i>
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-container">
          <div className="slicknav_menu">
            <nav id="navigation" className={`slicknav_nav navigation ${isNavVisible ? "d-block" : "d-none"} d-lg-inline-block`}>
              <ul>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive ? "current-menu-item" : ""}`
                    }
                    to="/home"
                    onClick={mobiletoggleNav}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="menu-item-has-children slicknav_parent slicknav_collapsed">
                  <span className="slicknav_parent-link slicknav_row">
                    <NavLink to="#" onClick={(e) => { e.preventDefault(); toggleCommunity(); }}>Community Members</NavLink>
                    <Link onClick={toggleCommunity} to="#" role="menuitem" aria-haspopup="true" tabIndex="-1" className="slicknav_item" style={{ outline: "none" }}>
                      <span className="slicknav_arrow">
                        {!showCommunity ? (
                          <i className="fas fa-plus"></i>
                        ) : (
                          <i className="fas fa-minus"></i>
                        )}
                      </span>
                    </Link>
                  </span>
                  <ul role="menu" className="slicknav_hidden" aria-hidden="true" style={{
                    height: showCommunity ? '315px' : '0px',
                    overflow: 'hidden',
                    transition: 'height 1s ease'
                  }} >
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/volunteers"
                        onClick={mobiletoggleNav}
                      >
                        Volunteers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/donors"
                        onClick={mobiletoggleNav}
                      >
                        Blood Donors
                      </NavLink>
                    </li>
                    <li><NavLink to="/skilled-persons" onClick={mobiletoggleNav}>NBC Members</NavLink></li>
                    <li><NavLink to="/nangal-heroes" onClick={mobiletoggleNav}>Nangal Heroes</NavLink></li>
                    <li><NavLink to="/become-volunteer" onClick={mobiletoggleNav}>Become a volunteer</NavLink></li>
                    <li><NavLink to="/become-donor" onClick={mobiletoggleNav}>Become a Blood Donor</NavLink></li>
                    <li><NavLink to="/become-nbc-member" onClick={mobiletoggleNav}>Become an NBC Member</NavLink></li>
                  </ul>
                </li>
                <li className="menu-item-has-children slicknav_parent slicknav_collapsed">
                  <span className="slicknav_parent-link slicknav_row">
                    <NavLink to="#" onClick={(e) => { e.preventDefault(); toggleMedia(); }}>Media & Events</NavLink>
                    <Link onClick={toggleMedia} to="#" role="menuitem" aria-haspopup="true" tabIndex="1" className="slicknav_item" style={{ outline: "none" }}>
                      <span className="slicknav_arrow">
                        {!showMedia ? (
                          <i className="fas fa-plus"></i>
                        ) : (
                          <i className="fas fa-minus"></i>
                        )}
                      </span>
                    </Link>
                  </span>
                  <ul role="menu" className="slicknav_hidden" aria-hidden="true"
                    style={{
                      height: showMedia ? 'auto' : '0px',
                      overflow: 'hidden',
                      transition: 'height 1s ease'
                    }}
                  >
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/events"
                        onClick={mobiletoggleNav}
                      >
                        Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "current-menu-item" : "")}
                        to="/blogs"
                        onClick={mobiletoggleNav}
                      >
                        Blogs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "current-menu-item" : "")}
                        to="/Newsarchive"
                        onClick={mobiletoggleNav}
                      >
                        News
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/gallery"
                        onClick={mobiletoggleNav}
                      >
                        Gallery
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children slicknav_parent slicknav_collapsed">
                  <span className="slicknav_parent-link slicknav_row">
                    <NavLink to="#" onClick={(e) => { e.preventDefault(); toggleDiscover(); }}>Discover Us</NavLink>
                    <Link onClick={toggleDiscover} to="#" role="menuitem" aria-haspopup="true" tabIndex="1" className="slicknav_item" style={{ outline: "none" }}>
                      <span className="slicknav_arrow">
                        {!showDiscover ? (
                          <i className="fas fa-plus"></i>
                        ) : (
                          <i className="fas fa-minus"></i>
                        )}
                      </span>
                    </Link>
                  </span>
                  <ul role="menu" className="slicknav_hidden" aria-hidden="true" style={{
                    height: showDiscover ? '135px' : '0px',
                    overflow: 'hidden',
                    transition: 'height 1s ease'
                  }} >
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/about"
                        onClick={toggleNav}
                      >
                        Who We Are
                      </NavLink>
                    </li><li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/team"
                        onClick={toggleNav}
                      >
                        Team
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/contact"
                        onClick={toggleNav}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {
                  !user ? (
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "current-menu-item" : ""
                        }
                        to="/auth/login"
                      >
                        Sign in
                      </NavLink>
                    </li>
                  ) : ("")
                }
                {
                  user &&
                  <Dropdown >
                    <Dropdown.Toggle id="dropdown-basic" className='btn-dark'>
                      <img src={user?.userProfile ? user?.userProfile : genericImg} alt="user" width="40" height="40" className="user_profile_image" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item><Link to={user.roleName.includes(ROLES.Admin) ? "/admin/user-profile" : "/user/edit-profile"}>
                        <i className="fa-solid fa-user px-2"></i>
                        Edit Profile
                      </Link>
                      </Dropdown.Item>
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/socialMedia-links">
                          <i className="fa-solid fa-users px-2"></i>
                          Social Media</Link>
                        </Dropdown.Item>
                      }
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/blogs">
                          <i className="fa-solid  fa-pen px-2 pt-1"></i>
                          Blogs</Link>

                        </Dropdown.Item>
                      }
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/events">
                          <i className="fa-solid fa-list px-2 pt-1"></i>
                          Events</Link>
                        </Dropdown.Item>
                      }
                      {!user.roleName.includes(ROLES.Admin) &&
                        <Dropdown.Item><Link to="/user/gallery">
                          <i className="fa-solid fa-image px-2 pt-1"></i>
                          Gallery</Link>
                        </Dropdown.Item>
                      }
                      <Dropdown.Item><Link to="/password/change-password">
                        <i className="fa-solid fa-key px-2"></i>
                        Change Password</Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout} style={{ color: 'red' }}>
                        <i className="fa-solid fa-arrow-right-from-bracket px-2"></i>
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;