import React from "react";
import { Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import unboundLogo from '../Assets/Images/unbound-logo.png';
import { TbLogs } from "react-icons/tb";

function MobileSideBar({ isShow, handleClose }) {
        return (
                <>
                        <Offcanvas show={isShow} onHide={handleClose} className='mobile_tab_sidebar'>
                                <Offcanvas.Header closeButton>
                                        <div className="canvas-logo">
                                                <Link to="#">
                                                        <img className="white-logo" src={unboundLogo} alt="logo" />
                                                </Link>
                                        </div>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                        <NavLink to="/admin/all-donors" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-list px-2"></i>
                                                <span>Donor List</span>
                                        </NavLink>
                                        <NavLink to="/admin/all-volunteers" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-address-card px-2 pt-1"></i>
                                                <span>Volunteer list</span>
                                        </NavLink>
                                        <NavLink to="/admin/all-SkilledPersons" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fas fa-graduation-cap px-2 pt-1"></i>
                                                <span>Skilled Persons</span>
                                        </NavLink>
                                        <NavLink to="/admin/heroes" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-sharp fa-solid fa-star-half-stroke"></i>
                                                <span>Nangal Heroees</span>
                                        </NavLink>
                                        <NavLink to="/admin/all-events" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-list px-2 pt-1"></i>
                                                <span>Events List</span>
                                        </NavLink>
                                        <NavLink to="/admin/allParticipantslist" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-list px-2 pt-1"></i>
                                                <span>Event Participants</span>
                                        </NavLink>
                                        <NavLink to="/admin/request-event" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fas fa-tasks px-2 pt-1"></i>
                                                <span>Pending Events Request</span>
                                        </NavLink>
                                        <NavLink to="/admin/all-blogs" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid  fa-pen px-2 pt-1"></i>
                                                <span>Blogs</span>
                                        </NavLink>
                                        <NavLink to="/admin/gallery" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-image px-2 pt-1"></i>
                                                <span>Gallery</span>
                                        </NavLink>
                                        <NavLink to="/admin/contact-requests" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-exchange-alt px-2 py-1"></i>
                                                <span>Contact Requests</span>
                                        </NavLink>
                                        <NavLink to="/admin/faqs" className={({ isActive }) => `p-2 active_link nav-link text-light text-uppercase ${isActive ? "active" : ""}`} onClick={handleClose}>
                                                <i className="fa-solid fa-question-circle px-2"></i>
                                                <span>Faq</span>
                                        </NavLink>
                                        <NavLink to="/admin/audit-logs" className={({ isActive }) => `nav-link text-light ${isActive ? "active" : ""}`} >
                                                <TbLogs />
                                                <span>{" "}Audit Logs</span>
                                        </NavLink>
                                </Offcanvas.Body>
                        </Offcanvas>
                </>
        );
}

export default MobileSideBar;
