import React from "react";
import unboundLogo from "../Assets/Images/unbound-logo.png";
import { Link } from "react-router-dom";

function Footer() {

  return (
    <>
      <footer id="colophon" className="site-footer footer-primary">
        <div className="top-footer">
          <div className="container">
            <div className="upper-footer">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <aside className="widget widget_text">
                    <div className="footer-logo">
                      <Link to="/home">
                        <img src={unboundLogo} alt="unboundLogo" />
                      </Link>
                    </div>
                    <div className="textwidget widget-text">
                      At Nangal By Cycle, we are truly humbled by the incredible generosity
                      and support of our donors. Their contributions make a real difference in the lives of those we
                      serve. Our donors make our vision a reality. We are tremendously grateful for their trust and
                      support as we work together to build a brighter future for all.
                    </div>
                  </aside>
                </div>
                <div className="col-lg-3 col-md-6">
                  <aside className="widget d-md-block d-none">
                    <h3 className="widget-title">Support</h3>
                    <ul>
                      <li>
                        <Link to={"/contact"}>
                          <span className="semi-bold">Contact Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/about"}>
                          <span className="semi-bold">Who We Are</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/faq"}>
                          <span className="semi-bold">FAQ</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/gallery"}>
                          <span className="semi-bold">Gallery</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/Bloodrequirement"}>
                          <span className="semi-bold">Blood Request Portal</span>
                        </Link>
                      </li>
                    </ul>
                  </aside>
                  <div className="row d-md-none d-block">
                    <div className="d-flex">
                      <div className="col-6">
                        <aside className="widget d-md-block">
                          <h3 className="widget-title">Support</h3>
                          <ul>
                            <li>
                              <Link to={"/contact"}>
                                <span className="semi-bold">Contact Us</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/about"}>
                                <span className="semi-bold">Who We Are</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/faq"}>
                                <span className="semi-bold">FAQ</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/gallery"}>
                                <span className="semi-bold">Gallery</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/Bloodrequirement"}>
                                <span className="semi-bold">Blood Request Portal</span>
                              </Link>
                            </li>
                          </ul>
                        </aside>
                      </div>
                      <div className="col-6">
                        <aside className="widget widget_map_hotspots">
                          <h3 className="widget-title">Other Links</h3>
                          <div className="widget-map">
                            <div className="hotspot">
                              <ul>
                                <li>
                                  <Link to={"/donors"}>
                                    <span className="semi-bold">Blood Donors</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/Volunteers"}>
                                    <span className="semi-bold">Volunteer</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/events"}>
                                    <span className="semi-bold">Event</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/skilled-persons"}>
                                    <span className="semi-bold">Skilled Person</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/team"}>
                                    <span className="semi-bold">Team</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/feedback"}>
                                    <span className="semi-bold">Give Feedback</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/e-certificates"}>
                                    <span className="semi-bold">E-certificates</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-block d-none">
                  <aside className="widget widget_map_hotspots">
                    <h3 className="widget-title">Other Links</h3>
                    <div className="widget-map">
                      <div className="hotspot">
                        <ul>
                          <li>
                            <Link to={"/donors"}>
                              <span className="semi-bold">Blood Donors</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Volunteers"}>
                              <span className="semi-bold">Volunteer</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/events"}>
                              <span className="semi-bold">Event</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/skilled-persons"}>
                              <span className="semi-bold">NBC Member</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/team"}>
                              <span className="semi-bold">Team</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/feedback"}>
                              <span className="semi-bold">Give Feedback</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/e-certificates"}>
                              <span className="semi-bold">E-certificates</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </aside>
                </div>
                <div className="col-lg-3 col-md-6">
                  <aside className="widget widget_text">
                    <h3 className="widget-title">Contact Information</h3>
                    <div className="textwidget widget-text">
                      <ul>
                        <li>
                          <a href="mailtop:info@domain.com">
                            <i
                              aria-hidden="true"
                              className="icon icon-envelope1"
                            ></i>
                            <span
                              className="__cf_email__"
                              data-cfemail="462f2820290622292b272f286825292b"
                            >
                              info@nangalbycycle.com
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>

              </div>
            </div>
            <div className="lower-footer" style={{ display: "none" }}>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="footer-newsletter">
                    <h5>Subscribe us for more update &amp; news !!</h5>
                    <form className="newsletter">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                      />
                      <button type="submit" className="button-round">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 text-right">
                  <div className="social-links">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.twitter.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <Link to="https://www.youtube.com/" target="_blank">
                          <i className="fab fa-youtube" aria-hidden="true"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/" target="_blank">
                          <i
                            className="fab fa-instagram"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/" target="_blank">
                          <i className="fab fa-linkedin" aria-hidden="true"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="policy.html">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="policy.html">Term &amp; Condition</a>
                      </li>
                      <li>
                        <a href="faq.html">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="copy-right text-center">
              Copyright © 2024-25 Nangal By Cycle. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
