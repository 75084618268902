import React, { useEffect, useState } from "react";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getEvent, getPaginatedEvents } from "../../Slice/events";
import Loader from "../../Components/Loader";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Carousel } from "react-bootstrap";
import { numberToString, stringToNumber } from "../../utils/encrypt";

function EventSingle() {
  const { title } = useParams();
  const lastIndex = title.lastIndexOf('-');
  const eventId = title.substring(lastIndex + 1);
  const intID = stringToNumber(eventId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event, events, isLoading } = useSelector((state) => state.event);
  const paginationState = { page: 1, pageSize: 9 };

  useEffect(() => {
    dispatch(getEvent(intID));
    dispatch(getPaginatedEvents(paginationState.page, paginationState.pageSize));
  }, [dispatch, intID]);

  // Calculate the time left until the event starts and remove countdown after the end date
  const calculateTimeLeft = () => {
    const startTime = new Date(event?.startDateTime);
    const endTime = new Date(event?.endDateTime);
    const now = new Date();

    if (now < startTime) {
      // Time left until event starts
      const difference = +startTime - +now;
      return {
        isUpcoming: true,
        timeLeft: {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        },
      };
    } else if (now >= startTime && now <= endTime) {
      // Time left during the event
      const difference = +endTime - +now;
      return {
        isUpcoming: false,
        timeLeft: {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        },
      };
    } else {
      // Event has ended
      return { isUpcoming: false, timeLeft: null };
    }
  };

  const [timeData, setTimeData] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeData(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeData]);

  const formatTime = (time) => moment(time).format("hh:mm A");
  const formatDate = (day) => moment(day).format("D MMMM, YYYY");

  const user = JSON.parse(localStorage.getItem("user"));

  const [state, setState] = useState({
    showModal: false,
    selectedEventId: null,
  });

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      selectedEventId: null,
    }));
  };

  const PopupModal = ({ onClose, eventId }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "8px",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          width: "400px",
          maxWidth: "80%",
          position: "relative",
          animation: "fadeIn 0.5s",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          &times;
        </button>

        <b><p>"It looks like you’re not logged in! Not a member yet? Sign up now to join the event and be part of the experience!".</p></b>
        <button
          style={{
            backgroundColor: "#f15b43",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => ((window.location.href = `/auth/skills?event=${numberToString(eventId)}`))}
        >
          Go to NBC Member Register
        </button>
        <button
          style={{
            backgroundColor: "#f15b43",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => ((window.location.href = `/auth/login?event=${numberToString(eventId)}`))}
        >
          Login
        </button>
      </div>
    </div>
  );

  const handleRegisterClick = (eventId) => {
    if (user) {
      // If user is logged in, redirect to the participation page
      window.location.href = `/event/participation/${numberToString(eventId)}`;
    } else {
      // If user is not logged in, show the modal
      setState((prevState) => ({
        ...prevState,
        showModal: true,
        selectedEventId: eventId,
      }));
    }
  };

  const currentDateIST = new Date();
  const eventExpiryDateTime = new Date(event?.endDateTime);
  const isExpired = eventExpiryDateTime < currentDateIST;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main">
          <Helmet>
            <title>Charity Events by Nangal by Cycle | Join & Support Us</title>
            <meta name="description" content="Discover upcoming charity events hosted by Nangal by Cycle. Join us to make a difference and support our mission of promoting rural culture and sustainability." />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Event details</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="event-page-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 primary right-sidebar">
                  {event ? (
                    <div className="event-detail-container">
                      <div className="event-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "20px"}}>
                        <h2 className="event-title">{event?.title}</h2>
                        {!isExpired && event?.allowParticipants === 1 && (
                          <button
                            className="button-round border_radius"
                            onClick={() => handleRegisterClick(event.eventId)}
                          >
                            Register
                          </button>
                        )}  
                      </div>
                      <figure className="event-feature-img">
                        <Carousel indicators={false} controls={false} slide={true} interval={3000}>
                          {event?.imageUrl && (
                            <Carousel.Item>
                              <img src={event?.imageUrl} alt="Main Event Thumbnail" />
                            </Carousel.Item>
                          )}
                          {event.images && event.images.length > 0 &&
                            event.images.map((image, index) => (
                              <Carousel.Item key={index}>
                                <img src={image.imageUrl} alt={`Event Image ${index + 1}`} />
                              </Carousel.Item>
                            ))}
                        </Carousel>
                      </figure>
                      {timeData.timeLeft && (
                        <div className="time-counter-wrap">
                          <div className="time-counter">
                            <div className="counter-time" style={{ zIndex: 10 }}>
                              <span className="counter-days">
                                {timeData.timeLeft.days}
                              </span>
                              <span className="label-text">Days</span>
                            </div>
                            <div className="counter-time" style={{ zIndex: 10 }}>
                              <span className="counter-hours">
                                {timeData.timeLeft.hours}
                              </span>
                              <span className="label-text">Hours</span>
                            </div>
                            <div className="counter-time" style={{ zIndex: 10 }}>
                              <span className="counter-minutes">
                                {timeData.timeLeft.minutes}
                              </span>
                              <span className="label-text">Minutes</span>
                            </div>
                            <div className="counter-time" style={{ zIndex: 10 }}>
                              <span className="counter-seconds">
                                {timeData.timeLeft.seconds}
                              </span>
                              <span className="label-text">Seconds</span>
                            </div>
                          </div>
                          <div className="event-text"></div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>Loading event...</div>
                  )}
                  <div className="icon-box-wrap">
                    <h2>Event Description</h2>
                    <div className="react_quill_editor" dangerouslySetInnerHTML={{ __html: event?.description }} />
                  </div>
                </div>
                <div className="col-lg-4 secondary">
                  <div className="sidebar">
                    <div className="widget widget-bg widget-detail">
                      <h4 className="bg-title">Event Details</h4>
                      <ul>
                        <li className="d-flex">
                          <h5>Starting time:</h5>
                          <span>{formatTime(event?.startDateTime)}</span>
                        </li>
                        <li className="d-flex">
                          <h5>Event date:</h5>
                          <span>{formatDate(event?.startDateTime)}</span>
                        </li>
                        <li className="d-flex">
                          <h5>Phone number:</h5>
                          <span>{event?.contact ? event.contact : 'XXXXXXXXXX'}</span>
                        </li>
                        <li className="d-flex">
                          <h5>Location:</h5>
                          <span>{event?.location}</span>
                        </li>
                      </ul>
                    </div>
                    {events.length > 1 && (
                      <div className="widget widget-bg icon-list-content text-center">
                        <h4 className="bg-title">Upcoming Events</h4>
                        <ul>
                          {events
                            .filter((element) => element.title !== title)
                            .slice(0, 4)
                            .map((element) => (
                              <li key={element.eventId}>
                                <Link
                                  to={`/event-single/${element?.eventId}`}
                                  onClick={() => navigate(`/event-single/${element?.eventId}`)}
                                >
                                  <i className="icon icon-arrow-right-circle"></i>
                                  {element?.title}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      {state.showModal && <PopupModal onClose={closeModal} eventId={state.selectedEventId} />}
    </>
  );
}

export default EventSingle;
